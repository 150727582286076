import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  downloadCSV,
  Filter,
  List,
  NumberField,
  ReferenceArrayInput,
  SelectArrayInput,
  TextField,
  useDataProvider,
  useListContext,
  useTranslate,
} from 'react-admin';
import { get, map } from 'lodash';
import jsonExport from 'jsonexport/dist';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import DateField from '../components/table/DateField';
import BoolField from '../components/table/BoolField';
import DateInput from '../components/DateField';
import { formatDateAsIso, formatIsoDate } from '../common/dateUtils';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';

const useStyles = makeStyles((theme) => ({
  picker: {
    width: '48%',
  },
  hint: {
    color: theme.palette.text.secondary,
  },
  kpiLabel: {
    fontSize: 16,
    fontWeight: 600,
  },
  kpiValue: {
    fontSize: 18,
    fontWeight: 500,
  },
  form: {
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
}));

const InvoiceFilter = (props) => {
  const translate = useTranslate();
  const {
    filterValues,
    setFilters,
  } = useListContext();
  const classes = useStyles();

  if (props.context === 'button') {
    return null;
  }

  const handleDateChange = (name) => (date) => {
    setFilters({ ...filterValues, [name]: date ? formatDateAsIso(date) : null }, []);
  };

  return (
    <Filter className={classes.form} variant="outlined" {...props}>
      <Box width="100%" display="flex" flexDirection="column" paddingTop={2} alwaysOn>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <ReferenceArrayInput
            className={classes.picker}
            source="fk_tenantId"
            reference="tenants"
            label="tenants.label"
            alwaysOn
            resettable
          >
            <SelectArrayInput
              variant="outlined"
              optionText="name"
              resettable
            />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            className={classes.picker}
            source="superTenantId"
            reference="super-tenants"
            label="superTenants.label"
            alwaysOn
            resettable
          >
            <SelectArrayInput
              variant="outlined"
              optionText="name"
              resettable
            />
          </ReferenceArrayInput>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <DateInput
            className={classes.picker}
            label={translate('common.startDate')}
            value={filterValues.startDate}
            maxDate={filterValues.endDate}
            onChange={handleDateChange('startDate')}
            disableFuture
            clearable
            alwaysOn
          />
          <DateInput
            className={classes.picker}
            label={translate('common.endDate')}
            value={filterValues.endDate}
            minDate={filterValues.startDate}
            onChange={handleDateChange('endDate')}
            disableFuture
            clearable
            alwaysOn
          />
        </Box>
      </Box>

    </Filter>
  );
};

const InvoiceKpis = () => {
  const {
    filterValues,
    page,
    perPage,
    currentSort,
  } = useListContext();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles();
  const [kpis, setKpis] = useState(null);

  useEffect(() => {
    dataProvider.getList('invoices-kpi', {
      pagination: { page, perPage },
      sort: currentSort,
      filter: filterValues,
    }).then((res) => {
      setKpis(get(res, 'data[0]'));
    });
    // eslint-disable-next-line
  }, [page, perPage, currentSort, filterValues]);

  if (!kpis) {
    return <span />;
  }

  // todo refactor into component
  return (
    <Card>
      <Box maxWidth={300} padding={1} display="flex" flexDirection="row" flexWrap="wrap">
        <Typography
          variant="subtitle1"
        >
          {translate('invoices.currentRange')}
        </Typography>
        {map(kpis.range, (value, key) => (
          <Box key={key} width="100%" padding={1} display="flex" flexDirection="column">
            <span className={classes.kpiLabel}>{translate(`invoices.fields.${key}`)}</span>
            <span className={classes.kpiValue}>{value}</span>
          </Box>
        ))}

        {kpis.past && (
          <>
            <Typography
              variant="subtitle1"
            >
              {`${translate('invoices.pastRange')}: ${formatIsoDate(kpis.pastStart)} - ${formatIsoDate(kpis.pastEnd)}`}
            </Typography>

            {map(kpis.past, (value, key) => (
              <Box
                key={`past-${key}`}
                width="100%"
                padding={1}
                display="flex"
                flexDirection="column"
              >
                <span className={classes.kpiLabel}>{translate(`invoices.fields.${key}`)}</span>
                <span className={classes.kpiValue}>{value}</span>
              </Box>
            ))}
          </>
        )}

        {kpis.diff && (
          <>
            <Typography
              variant="subtitle1"
            >
              {translate('invoices.fields.totalDiff')}
            </Typography>

            {map(kpis.diff, (value, key) => (
              <Box
                key={`past-${key}`}
                width="100%"
                padding={1}
                display="flex"
                flexDirection="column"
              >
                <span className={classes.kpiLabel}>{translate(`invoices.fields.${key}`)}</span>
                <span className={classes.kpiValue}>{value}</span>
              </Box>
            ))}
          </>
        )}
      </Box>
    </Card>
  );
};

const InvoiceList = (props) => {
  const translate = useTranslate();

  const exporter = (invoices) => {
    jsonExport(invoices.map((inv) => {
      const {
        // eslint-disable-next-line camelcase
        createdAt, updatedAt, fk_tenantId, id, ...toExport
      } = inv;
      return toExport;
    }), {
      headers: ['name', 'startDate', 'endDate', 'countDriverType0', 'countDriverType1', 'totalDrivers', 'includeUVV', 'uvvDrivers', 'smsCount'],
      rename: [translate('invoices.fields.tenantName'), translate('invoices.fields.startDate'), translate('invoices.fields.endDate'), translate('invoices.fields.countDriverType0'), translate('invoices.fields.countDriverType1'), translate('invoices.fields.totalDrivers'), translate('invoices.fields.includeUVV'), translate('invoices.fields.uvvDrivers'), translate('invoices.fields.smsCount')],
      rowDelimiter: ';',
    }, (err, csv) => {
      downloadCSV(csv, `invoices_${new Date().toISOString()}`); // download as 'posts.csv` file
    });
  };

  return (
    <div>
      <TitleBreadcrumbs title={translate(props.options.label)} />
      <List exporter={exporter} filters={<InvoiceFilter />} aside={<InvoiceKpis />} {...props}>
        <Datagrid rowClick="edit">
          <TextField
            source="name"
            label="invoices.fields.tenantName"

          />
          <DateField source="startDate" label="invoices.fields.startDate" />
          <DateField source="endDate" label="invoices.fields.endDate" />
          <NumberField source="countDriverType0" label="invoices.fields.countDriverType0" />
          <NumberField source="countDriverType1" label="invoices.fields.countDriverType1" />
          <NumberField source="totalDrivers" label="invoices.fields.totalDrivers" />
          <BoolField source="includeUVV" label="invoices.fields.includeUVV" />
          <NumberField source="uvvDrivers" label="invoices.fields.uvvDrivers" />
          <NumberField source="smsCount" label="invoices.fields.smsCount" />
        </Datagrid>
      </List>
    </div>
  );
};

export default InvoiceList;
