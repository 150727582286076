import { charsets, PasswordPolicy } from 'password-sheriff';

export const pwPolicy = new PasswordPolicy({
  contains: {
    expressions: [
      charsets.upperCase,
      charsets.lowerCase,
      charsets.numbers,
      charsets.specialCharacters,
    ],
  },
  length: {
    minLength: 16,
  },
});

export const validatePasswordPolicy = (translate) => (password) => {
  if (password && !pwPolicy.check(password)) {
    const missing = pwPolicy.missing(password);
    const invalidRules = missing.rules.filter((r) => !r.verified);
    if (invalidRules.length > 0) {
      return translate(`common.pw.${invalidRules[0].code}`);
    }
  }

  return undefined;
};

export const validatePassword = (password) => pwPolicy.check(password);

export const validateEmail = (email) => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);

export const validatePhone = (phoneNumber) => /(\(?([\d \-)–+/(]+){6,}\)?([ .\-–/]?)([\d]+))/.test(phoneNumber);
