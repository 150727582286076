import React from 'react';
import { formatIsoDateTime } from '../../common/dateUtils';

const DateTimeField = ({ record, source }) => {
  if (record && record[source]) {
    return <span>{formatIsoDateTime(record[source])}</span>;
  }
  return <span />;
};

export default DateTimeField;
