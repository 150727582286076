import { stringify } from 'query-string';
import { forEach } from 'lodash';
import simpleRestProvider from 'ra-data-simple-rest';
import httpClient from './httpClient';
import { baseApi } from '../common/envUtils';

const dataProvider = simpleRestProvider(baseApi, httpClient);

const resourceDataProvider = {
  ...dataProvider,
  getList: (resource, params) => {
    let url = `${baseApi}/${resource}`;
    if (params) {
      const { page, perPage } = params.pagination || { page: 1, perPage: 100 };
      const { field, order } = params.sort || {};

      const { q, customFilter, ...filter } = params.filter;

      const listRegex = /\[(-?\d,?)+]/;
      let startDate;
      let endDate;
      forEach(filter, (values, key) => {
        if (key.includes('status')) {
          filter[key] = values.flatMap((val) => {
            if (typeof val === 'string' && val.match(listRegex)) {
              return val.match(/([-?0-9]+)/g);
            }
            return [val];
          });
        }
        if (key === 'startDate') {
          startDate = values;
          delete filter[key];
        }
        if (key === 'endDate') {
          endDate = values;
          delete filter[key];
        }
      });

      const query = {
        limit: perPage,
        offset: (page - 1) * perPage,
        orderBy: field && order ? `${field} ${order}` : null,
        search: q || null,
        filter: params.filter && Object.values(filter).length > 0 ? JSON.stringify(filter) : null,
        customFilter,
        startDate,
        endDate,
      };

      url += `?${stringify(query, {
        skipEmptyString: true,
        skipNull: true,
        encode: true,
      })}`;
    }

    if (resource === 'templates') {
      return httpClient(url).then(({ json }) => {
        const newData = json.data.map((d) => ({ ...d, id: d.templateType }));
        return { ...json, data: newData };
      });
    }

    return httpClient(url).then(({ json }) => json);
  },
  getMany: (resource, params) => {
    const query = {
      filter: params && params.ids && params.ids.length > 0
        ? JSON.stringify({ id: params.ids }) : null,
    };

    const url = `${baseApi}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => json);
  },
  getOne: (resource, params) => httpClient(`${baseApi}/${resource}/${params.id}`).then(({ json }) => (resource === 'templates' ? ({
    data: {
      ...json,
      id: json.templateType,
    },
  }) : ({
    data: json,
  }))),
  delete: (resource, params) => {
    let url = `${baseApi}/${resource}/${params.id}`;
    if (params.final) {
      url += '?final=true';
    }

    return httpClient(url, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));
  },
};

const resourceNameMapper = (callback) => (resource, params) => {
  const res = resource.replace('_', '/');
  return callback(res, params);
};

forEach(resourceDataProvider, (callbackFn, key) => {
  resourceDataProvider[key] = resourceNameMapper(callbackFn);
});

export default resourceDataProvider;
