import axios from 'axios';

function axiosRequest(url, options = {}) {
  const method = options.method || 'GET';
  const data = options.body || {};
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
  }
  const headers = options.headers || {};
  return axios({
    method,
    url,
    data,
    headers,
  }).then((response) => {
    console.log(`${method.toUpperCase()} ${url}:`, response.data);
    return response.data;
  }).catch((error) => {
    console.error(`Error during ${method.toUpperCase()} ${url}:`, error);
    throw error;
  });
}
export default axiosRequest;
