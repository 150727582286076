import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDataProvider, useTranslate } from 'react-admin';

const TenantSelect = ({ selected, onChange, superTenantId }) => {
  const [tenants, setTenants] = useState([]);
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const handleChange = (e, value) => {
    onChange(value);
  };

  const fetchTenants = (e, search) => dataProvider.getList('tenants', {
    pagination: { page: 1, perPage: 50 },
    filter: superTenantId ? { fk_superTenantId: superTenantId, q: search } : { q: search },
    sort: {},
  })
    .then((res) => {
      if (res && res.data) {
        setTenants(res.data);
        return res.data;
      }
      return [];
    });

  useEffect(() => {
    fetchTenants();
    // eslint-disable-next-line
  }, [superTenantId]);

  return (
    <Autocomplete
      value={selected}
      onInputChange={fetchTenants}
      onChange={handleChange}
      getOptionLabel={(option) => option && option.name}
      options={tenants}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={translate('tenants.label')}
          variant="outlined"
        />
      )}
    />
  );
};

export default TenantSelect;
