import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Notification, useAuthProvider, useNotify, useTranslate,
} from 'react-admin';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/core/styles';
import { parse } from 'query-string';
import Icon from '../assets/img/edding-logo.svg';
import theme from '../common/theme';
import { setPasswordAction, setResetCodeAction, setUsernameAction } from '../redux/authReducer';
import { validatePassword, validatePasswordPolicy } from '../common/validateUtils';
import PasswordTextField from '../components/PasswordTextField';

const useStyles = makeStyles(() => ({
  logoImage: {
    objectFit: 'scale-down',
    width: 445,
    height: 128,
  },
  titleContainer: {
    paddingBottom: 38,
    borderBottom: (props) => `1px solid ${props.secondaryColor}`,
  },
}));

const VerifyPage = ({
  username,
  onUsernameChange,
  resetCode,
  onResetCodeChange,
  password,
  onPasswordChange,
  history,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const authProvider = useAuthProvider();
  const notify = useNotify();

  useEffect(() => {
    const { code, email } = parse(history.location.search);
    onResetCodeChange(code);
    onUsernameChange(email);
    onPasswordChange('');
    // eslint-disable-next-line
  }, []);

  const submit = (e) => {
    e.preventDefault();
    authProvider.verify({ username, resetCode, newPassword: password })
      .then(() => {
        notify('pages.passwordVerify.successMessage');
        history.push('/login');
      })
      .catch(() => notify('ra.auth.sign_in_error'));
  };

  return (
    <ThemeProvider theme={theme}>
      <Container fixed>
        <Box
          height="90vh"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            height="80vh"
            maxHeight={700}
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
          >

            <img className={classes.logoImage} src={Icon} alt="company_logo" />

            <Box className={classes.titleContainer}>
              <Typography variant="h3">{translate('pages.passwordVerify.title')}</Typography>
            </Box>
            <form onSubmit={submit}>
              <Box
                maxWidth={520}
                width="50vw"
                height={300}
                display="flex"
                flexDirection="column"
                flexWrap="wrap"
                justifyContent="space-around"
              >
                <TextField
                  label={translate('ra.auth.username')}
                  name="username"
                  type="username"
                  value={username}
                  onChange={(e) => onUsernameChange(e.target.value)}
                  variant="outlined"
                  fullWidth
                />

                <TextField
                  label={translate('pages.passwordVerify.resetCode')}
                  name="resetCode"
                  value={resetCode}
                  onChange={(e) => onResetCodeChange(e.target.value)}
                  variant="outlined"
                  fullWidth
                />

                <PasswordTextField
                  label={translate('pages.passwordVerify.newPassword')}
                  name="password"
                  error={!validatePassword(password)}
                  helperText={validatePasswordPolicy(translate)(password)}
                  value={password}
                  onChange={(e) => onPasswordChange(e.target.value)}
                  variant="outlined"
                  fullWidth
                />

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!username}
                >
                  {translate('pages.passwordVerify.button')}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
        <Notification />
      </Container>

    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  username: state.auth.username,
  resetCode: state.auth.resetCode,
  password: state.auth.password,
});

const mapDispatchToProps = (dispatch) => ({
  onUsernameChange: (username) => {
    dispatch(setUsernameAction(username));
  },

  onResetCodeChange: (resetCode) => {
    dispatch(setResetCodeAction(resetCode));
  },

  onPasswordChange: (password) => {
    dispatch(setPasswordAction(password));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPage);
