import React from 'react';
// import { useListContext } from 'react-admin';
import { Filter, TextInput } from 'react-admin';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useOutlinedInputStyles } from '../../common/theme';

const SearchFilter = (props) => (
  <Filter {...props} variant="outlined">
    <TextInput
      style={{ width: '100%' }}
      resettable
      fullWidth
      helperText={false}
      source="q"
      label=""
      placeholder="Suchen"
      variant="outlined"
      alwaysOn
      InputProps={{
        classes: useOutlinedInputStyles(),
        startAdornment: (
          <InputAdornment>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
    {props.children}
  </Filter>
);
// const {
//   filterValues,
//   setFilters,
// } = useListContext();

export default SearchFilter;
