import { useDataProvider, usePermissions } from 'react-admin';
import { useEffect, useMemo, useState } from 'react';
import PermissionUtils from '../common/permissionUtils';

/**
 * Custom hook to fetch data from the data provider.
 *
 * @function useDataPoint
 * @param {string} key - The key representing the resource to fetch data for.
 * @param {string} [search=''] - Optional search query.
 * @param {{superTenantId?: string}} [parameters={}] - Optional filter parameters
 * @returns {Object} An object containing the data, loading state, and error state.
 * @property {Array} data - The fetched data.
 * @property {boolean} loading - Whether the data is currently being fetched.
 * @property {Error} error - Any error that occurred during data fetching.
 *
 * @example
 * const { data, loading, error } = useDataPoint('posts');
 */

export function useDataPoint(key, search = '', { superTenantId } = {}) {
  const dataProvider = useDataProvider();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    dataProvider.getList(key, {
      pagination: {
        page: 1,
        perPage: 50,
      },
      filter: superTenantId ? { fk_superTenantId: superTenantId, q: search } : { q: search },
      sort: {},
    })
      .then((result) => {
        setData(result.data);
      })
      .catch((error_) => {
        setError(error_);
      }).finally(() => {
        setLoading(false);
      });
  }, [key]);

  return {
    data,
    error,
    loading,
  };
}

/**
 * Custom hook to manage tenant data and permissions.
 *
 * @function useTenants
 * @param {string} [superTenantId] - Optional id of the superTenant to filter the search results.
 * @returns {Object} An object containing the current tenant ID and all tenants data.
 * @property {string | undefined} current - The current tenant ID.
 * @property {Array | null} all - The list of all tenants or null if not loaded.
 *
 * @example
 * const { current, all } = useTenants();
 */
export function useTenants(superTenantId) {
  const [tenantId, setTenantId] = useState(undefined);
  const { data } = useDataPoint('tenants', '', { superTenantId });
  const permissionsBase = usePermissions();
  const permissions = useMemo(() => new PermissionUtils(permissionsBase), [permissionsBase]);

  useEffect(() => {
    const tenantIdValue = permissions.getTenantId();
    if (tenantIdValue) {
      setTenantId(tenantIdValue);
    }
  }, [permissions]);

  if (tenantId && data.length > 0) {
    return {
      current: tenantId,
      all: data,
    };
  }

  return {
    current: tenantId,
    all: null,
  };
}
