import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  FormWithRedirect,
  ReferenceInput,
  required,
  SaveButton,
  TextInput,
  usePermissions,
  useTranslate,
} from 'react-admin';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { parse } from 'query-string';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import FormSubtitle from '../components/form/FormSubtitle';
import PermissionUtils from '../common/permissionUtils';

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 40px 30px 40px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  halfWidth: {
    flexBasis: '48%',
  },
}));

const GroupCreateToolbar = (props) => {
  const {
    invalid, pristine, saving, handleSubmitWithRedirect,
  } = props;
  const transformBeforeSubmit = (data) => ({
    ...data,
    tenantIds: data.tenantIds || [],
  });
  return (
    <div className={{ backgroundColor: 'inherit' }}>
      <SaveButton
        variant="contained"
        saving={saving}
        transform={transformBeforeSubmit}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        label="groups.actions.save"
        redirect="edit"
        submitOnEnter={false}
        disabled={invalid || pristine}
      />
    </div>
  );
};

const GroupCreate = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const permissions = new PermissionUtils(usePermissions());

  const superTenantId = parse(props.history.location.search)?.superTenantId
    || permissions.getSuperTenantId();

  return (
    <div>
      <TitleBreadcrumbs
        path={[
          { label: translate('groups.plural'), to: '/#/groups' },
        ]}
        title={translate('groups.actions.edit')}
      />
      <Create {...props}>
        <FormWithRedirect
          initialValues={{
            fk_superTenantId: superTenantId,
          }}
          render={(formData) => (
            <form className={classes.container}>
              <Box display="flex" flexDirection="row" width="100%">
                <Box minWidth={360} flex={1} display="flex" flexDirection="column">
                  <FormSubtitle>{translate('groups.sections.masterData')}</FormSubtitle>

                  <TextInput
                    source="name"
                    label="groups.fields.name"
                    variant="outlined"
                    margin="none"
                    validate={required()}
                  />

                  <ReferenceInput
                    key="superTenant"
                    label="groups.fields.superTenant"
                    source="fk_superTenantId"
                    reference="super-tenants"
                    filter={null}
                  >
                    <AutocompleteInput
                      fullWidth
                      margin="none"
                      variant="outlined"
                      optionText="name"
                      validate={required()}
                    />
                  </ReferenceInput>
                </Box>

                <Box minWidth={360} flex={2} pl={2} display="flex" flexDirection="column">
                  <FormSubtitle>{translate('groups.sections.tenants')}</FormSubtitle>

                  <ReferenceInput
                    label="groups.fields.tenants"
                    source="tenantIds"
                    reference="tenants"
                    multiple
                    filter={{ fk_superTenantId: superTenantId }}
                  >
                    <AutocompleteArrayInput
                      fullWidth
                      margin="none"
                      variant="outlined"
                      optionText="name"
                    />
                  </ReferenceInput>
                </Box>

              </Box>
              <GroupCreateToolbar {...formData} />
            </form>
          )}
        />
      </Create>
    </div>
  );
};
export default GroupCreate;
