/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useInput, useTranslate } from 'react-admin';
import DateField from '../DateField';
import { formatDateAsIso } from '../../common/dateUtils';

const FormDateField = (props) => {
  const {
    input: { name, onChange, value },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const translate = useTranslate();

  const handleChange = (date) => {
    onChange(formatDateAsIso(date));
  };

  return (
    <DateField
      name={name}
      label={translate(props.label)}
      classes={props.classes}
      value={value === '' ? null : value}
      onChange={handleChange}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
      style={props.style}
    />
  );
};

export default FormDateField;
