/**
 * Generates a filter object based on the user's permissions.
 *
 * @function getTenantFilter
 * @param {Object} permissions - An instance of PermissionUtils containing the user's permissions.
 * @returns {Object | null} A filter object to be used in queries, or null if no specific filter
 *     applies.
 *
 * @example
 * const filter = getTenantFilter(permissions);
 * if (filter) {
 *   // Use the filter in your query
 * }
 *
 * @description
 * This function checks the user's permissions and returns a filter object based on their role.
 * - If the user is a tenant admin, it returns a filter with the super tenant ID.
 * - If the user is a group admin, it returns a filter with the group ID.
 * - If the user has no special admin roles, it returns null.
 */
// eslint-disable-next-line import/prefer-default-export
export function getTenantFilter(permissions) {
  if (permissions.isTenantAdmin()) {
    return {
      fk_superTenantId: permissions.getSuperTenantId(),
    };
  }
  if (permissions.isGroupAdmin()) {
    return { fk_groupId: permissions.getGroupId() };
  }
  return null;
}

// Function to check if a year is a leap year
function isLeapYear(year) {
  // A year is a leap year if it is divisible by 4 and not divisible by 100,
  // or it is divisible by 400.
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}

// Function to validate a given date
export function isDateValid(day, month, year) {
  // Check if month is in the range 1-12
  if (month < 1 || month > 12) {
    return false;
  }

  // Days in each month, February (index 1) has 28 days initially
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years in February
  if (month === 2 && isLeapYear(year)) {
    daysInMonth[1] = 29;
  }

  // Check if day is in the valid range for the given month
  if (day < 1 || day > daysInMonth[month - 1]) {
    return false;
  }

  return true;
}
