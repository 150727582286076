import React from 'react';
import {
  Create, FormWithRedirect, SaveButton, useNotify, usePermissions, useTranslate,
} from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import UserForm from './UserForm';
import PermissionUtils from '../common/permissionUtils';

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: 'inherit',
  },
}));

const adminDefaultValues = {
  role: 1, canRead: true, canEdit: true, canManage: true, isNotified: true,
};

const UserCreateToolbar = (props) => {
  const permissions = new PermissionUtils(usePermissions());
  const classes = useStyles();
  const {
    invalid, pristine, saving, handleSubmitWithRedirect,
  } = props;

  const transformBeforeSubmit = (data) => ({
    ...data,
    fk_tenantId: data.fk_tenantId || permissions.getTenantId(),
  });
  return (
    <div className={classes.toolbar}>
      <SaveButton
        variant="contained"
        saving={saving}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        label="users.actions.save"
        transform={transformBeforeSubmit}
        redirect={false}
        submitOnEnter={false}
        disabled={invalid || pristine}
      />
    </div>
  );
};

const UserCreate = (props) => {
  const translate = useTranslate();
  const notify = useNotify();

  const onFailure = () => {
    notify('pages.userCreate.error', 'warning');
  };

  return (
    <div style={{ maxWidth: 800 }}>
      <TitleBreadcrumbs
        title={translate('pages.userCreate.title')}
      />
      <Create onFailure={onFailure} {...props}>
        <FormWithRedirect
          initialValues={adminDefaultValues}
          render={(formProps) => (
            <UserForm
              formProps={formProps}
              isCreate
              toolbar={<UserCreateToolbar {...formProps} />}
            />
          )}
        />
      </Create>
    </div>
  );
};

export default UserCreate;
