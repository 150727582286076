import React, { useEffect, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import httpClient from '../providers/httpClient';
import { baseApi } from '../common/envUtils';
import AdminHeader from './AdminHeader';

const codesApiUrl = `${baseApi}/label-codes`;

const LabelCodesPage = ({ location }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const [editCode, setEditCode] = useState('');
  const [codes, setCodes] = useState([]);

  const fetchCodes = async () => {
    const { json } = await httpClient(codesApiUrl);
    setCodes(json);
  };

  useEffect(() => {
    fetchCodes();
  }, []);

  const deleteCode = async (code) => {
    await httpClient(`${codesApiUrl}/${code}`, { method: 'DELETE' });
    notify('common.success');
    setCodes(codes.filter((c) => c !== code));
  };

  const createCode = async () => {
    if (editCode.includes(',')) {
      const { json } = await httpClient(`${codesApiUrl}/bulk`, {
        method: 'POST', body: JSON.stringify({ codes: editCode.replaceAll(/\s+/g, '').split(',') }),
      });
      setCodes([...codes, ...json.map(({ code }) => code)]);
    } else {
      const { json } = await httpClient(`${codesApiUrl}`, {
        method: 'POST', body: JSON.stringify({ code: editCode }),
      });
      if (json.code) {
        setCodes([...codes, json.code]);
      }
    }
    notify('common.success');
    setEditCode('');
  };

  const renderCodes = () => codes.sort().map((code) => (
    <Chip
      key={code}
      label={code}
      variant="outlined"
      onDelete={() => deleteCode(code)}
    />
  ));

  return (
    <Box width="100%">
      <AdminHeader active={location.pathname} />

      <Paper>
        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" padding={2}>
          {renderCodes()}
        </Box>
        <Box display="flex" flexDirection="row" padding={2}>
          <TextField
            label={translate('drivers.fields.licenseLabelCode')}
            name="resetCode"
            value={editCode}
            onChange={(e) => setEditCode(e.target.value)}
            variant="outlined"
          />

          <IconButton
            variant="contained"
            color="primary"
            type="submit"
            onClick={createCode}
            disabled={!editCode || editCode.length < 5}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Paper>
    </Box>
  );
};

export default LabelCodesPage;
