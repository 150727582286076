import React from 'react';
import {
  DeleteButton,
  Edit,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useTranslate,
} from 'react-admin';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import FormHtmlEditor from '../components/form/FormHtmlEditor';

const TemplateEditToolbar = (props) => {
  const {
    invalid, pristine, saving, handleSubmitWithRedirect,
  } = props;

  return (
    <Toolbar
      {...props}
      style={{
        backgroundColor: 'inherit',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <SaveButton
        variant="contained"
        saving={saving}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        label="templates.actions.save"
        redirect={false}
        submitOnEnter={false}
        disabled={invalid || pristine}
      />
      <DeleteButton
        label="templates.actions.reset"
      />
    </Toolbar>
  );
};

const TemplateEdit = (props) => {
  const translate = useTranslate();
  return (
    <div>
      <TitleBreadcrumbs
        path={[
          { label: translate('pages.admin.title'), to: 'tenants' },
        ]}
        title={translate('templates.actions.edit')}
      />
      <Edit {...props}>
        <SimpleForm margin={10} toolbar={<TemplateEditToolbar />}>
          <TextInput
            source="subject"
            label="templates.fields.subject"
            variant="outlined"
            margin="none"
            validate={required()}
          />
          <TextInput
            source="smsBody"
            label="templates.fields.smsBody"
            variant="outlined"
            multiline
            fullWidth
            margin="none"
          />
          <FormHtmlEditor
            source="body"
          />
        </SimpleForm>
      </Edit>
    </div>
  );
};

export default TemplateEdit;
