import React from 'react';
import {
  Datagrid, downloadCSV, List, NumberField, TextField, useTranslate,
} from 'react-admin';

import jsonExport from 'jsonexport/dist';
import InvoiceHeader from './InvoiceHeader';
import DateField from '../components/table/DateField';

const SupertenantInvoiceList = (props) => {
  const { basePath } = props;
  const translate = useTranslate();

  const exporter = (invoices) => {
    jsonExport(invoices.map((inv) => {
      const {
        // eslint-disable-next-line camelcase
        id, supertenantid, ...toExport
      } = inv;
      return toExport;
    }), {
      headers: ['name', 'startdate', 'enddate', 'countdrivertype0', 'countdrivertype1', 'totaldrivers', 'uvvdrivers'],
      rename: [translate('superTenantInvoices.fields.name'), translate('superTenantInvoices.fields.startdate'), translate('superTenantInvoices.fields.enddate'), translate('superTenantInvoices.fields.countdrivertype0'), translate('superTenantInvoices.fields.countdrivertype1'), translate('superTenantInvoices.fields.totaldrivers'), translate('superTenantInvoices.fields.uvvdrivers')],
      rowDelimiter: ';',
    }, (err, csv) => {
      downloadCSV(csv, `invoices_${new Date().toISOString()}`); // download as 'posts.csv` file
    });
  };
  return (
    <div>
      <InvoiceHeader active={basePath} />
      <List exporter={exporter} {...props}>
        <Datagrid rowClick="edit">
          <TextField source="name" label="superTenantInvoices.fields.name" />
          <DateField source="invoicedate" label="superTenantInvoices.fields.invoicedate" />
          <DateField source="startdate" label="superTenantInvoices.fields.startdate" />
          <DateField source="enddate" label="superTenantInvoices.fields.enddate" />
          <NumberField
            source="countdrivertype0"
            label="superTenantInvoices.fields.countdrivertype0"
          />
          <NumberField
            source="countdrivertype1"
            label="superTenantInvoices.fields.countdrivertype1"
          />
          <NumberField source="totaldrivers" label="superTenantInvoices.fields.totaldrivers" />
          <NumberField source="uvvdrivers" label="superTenantInvoices.fields.uvvdrivers" />
        </Datagrid>
      </List>
    </div>
  );
};

export default SupertenantInvoiceList;
