import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PopoverMenu from './PopoverMenu';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 170,
    padding: 9,
    borderRadius: 4,
    border: `solid 1px ${theme.palette.primary.light}`,
    backgroundColor: theme.palette.white.main,
    boxShadow: (props) => (props.open ? '0px 8px 10px 1px rgba(0,0,0,0.14)' : null),
  },
  label: {
    color: (props) => (props.menuOpen || props.selected ? 'black' : null),
  },
  indicator: {
    width: 18,
    height: 18,
    fontSize: 11,
    fontWeight: 600,
    borderRadius: '50%',
    border: `solid 1px ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
  },
  menuIndicator: {
    color: theme.palette.gray.main,
  },
}));

const SelectFilter = ({
  label, choices, selected, onChange, width, disabled, single,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles({
    open: Boolean(anchorEl),
    selected: Array.isArray(selected) ? selected.length > 0 : !isNil(selected),
  });

  const handleClick = (id) => (event) => {
    event.stopPropagation();

    if (disabled) {
      return;
    }

    if (single) {
      if (selected === id) {
        onChange(null);
      } else {
        onChange(id);
      }
    } else if (selected.includes(id)) {
      onChange(selected.filter((e) => e !== id));
    } else {
      onChange([...selected, id]);
    }
  };

  const menuOptions = choices.map(({ id, name }) => ({
    icon: (single ? id === selected : selected.includes(id))
      ? <CheckBoxIcon color={disabled ? 'disabled' : 'primary'} />
      : <CheckBoxOutlineBlankIcon />,
    label: name,
    onClick: handleClick(id),
  }));

  const renderLabel = () => {
    if (single) {
      return (
        <>
          <Typography
            className={classes.label}
            variant="subtitle2"
          >
            {!isNil(selected) && !Array.isArray(selected)
              ? choices.find((c) => c.id === selected).name : label}
          </Typography>
          {anchorEl ? <KeyboardArrowUpIcon className={classes.menuIndicator} />
            : <KeyboardArrowDownIcon className={classes.menuIndicator} />}
        </>
      );
    }

    return (
      <>
        {(selected.length > 0) && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.indicator}
          >
            {selected.length}
          </Box>
        )}
        <Typography className={classes.label} variant="subtitle2">{label}</Typography>
        {anchorEl ? <KeyboardArrowUpIcon className={classes.menuIndicator} />
          : <KeyboardArrowDownIcon className={classes.menuIndicator} />}
      </>
    );
  };

  return (
    <div>
      <Box
        className={classes.container}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {renderLabel()}
      </Box>
      <PopoverMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        menuOptions={menuOptions}
        width={width || 200}
      />
    </div>
  );
};

SelectFilter.propTypes = {
  label: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  selected: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

SelectFilter.defaultProps = {
  selected: [],
};

export default SelectFilter;
