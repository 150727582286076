import React from 'react';
import {
  Create, FormWithRedirect, SaveButton, useRedirect, useRefresh, useTranslate,
} from 'react-admin';
import SuperTenantForm from './SuperTenantForm';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';

const SuperTenantCreateToolbar = (props) => {
  const {
    invalid, pristine, saving, handleSubmitWithRedirect,
  } = props;
  return (
    <div className={{ backgroundColor: 'inherit' }}>
      <SaveButton
        variant="contained"
        saving={saving}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        label="superTenants.actions.save"
        redirect
        submitOnEnter={false}
        disabled={invalid || pristine}
      />
    </div>
  );
};

const SuperTenantCreate = (props) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    redirect(`/super-tenants/${data.id}/edit`);
    refresh();
  };

  return (
    <div>
      <TitleBreadcrumbs
        path={[
          { label: translate('pages.admin.title'), to: 'superTenants' },
        ]}
        title={translate('superTenants.actions.create')}
      />
      <Create {...props} onSuccess={onSuccess}>
        <FormWithRedirect
          render={(formProps) => (
            <SuperTenantForm
              formProps={formProps}
              toolbar={<SuperTenantCreateToolbar {...formProps} />}
            />
          )}
        />
      </Create>
    </div>
  );
};

export default SuperTenantCreate;
