import React from 'react';
import {
  Create, FormWithRedirect, SaveButton, usePermissions, useTranslate,
} from 'react-admin';

import { parse } from 'query-string';

import TenantForm from './TenantForm';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import PermissionUtils from '../common/permissionUtils';

const TenantCreateToolbar = (props) => {
  const {
    invalid, pristine, saving, handleSubmitWithRedirect,
  } = props;
  return (
    <div className={{ backgroundColor: 'inherit' }}>
      <SaveButton
        variant="contained"
        saving={saving}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        label="tenants.actions.save"
        redirect="edit"
        submitOnEnter={false}
        disabled={invalid || pristine}
      />
    </div>
  );
};

const TenantCreate = (props) => {
  const translate = useTranslate();
  const permissions = new PermissionUtils(usePermissions());

  const { superTenantId, groupId } = parse(props.history.location.search);

  return (
    <div style={{ maxWidth: 600 }}>
      <TitleBreadcrumbs
        path={[
          { label: translate('pages.admin.title'), to: 'tenants' },
        ]}
        title={translate('tenants.actions.create')}
      />
      <Create {...props}>
        <FormWithRedirect
          initialValues={{
            active: true,
            type1UVV: true,
            includeUVVLKW: false,
            isPkw: true,
            fk_superTenantId: superTenantId || permissions.getSuperTenantId(),
            fk_groupId: groupId || permissions.getGroupId(),
            useReminder: true,
            useTriggerEmail: true,
            useInterval: true,
          }}
          render={(formProps) => (
            <TenantForm formProps={formProps} toolbar={<TenantCreateToolbar {...formProps} />} />
          )}
        />
      </Create>
    </div>
  );
};

export default TenantCreate;
