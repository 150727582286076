import React, { useEffect } from 'react';
import {
  Create,
  FormWithRedirect,
  SaveButton,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {
  usePostHog,
  useFeatureFlagEnabled,
} from 'posthog-js/react';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import DriverForm from './DriverForm';
import PermissionUtils from '../common/permissionUtils';
import { NewDriverFormWrapper } from '../next-gen/add-driver';

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: 'inherit',
  },
}));

const transformBeforeSubmit = (data) => ({
  ...data,
  licenseLabelCode: data.licenseLabelCode ? data.licenseLabelCode.trim() : null,
});

const DriverCreateToolbar = (props) => {
  const classes = useStyles();
  const {
    invalid, pristine, saving, handleSubmitWithRedirect,
  } = props;
  return (
    <div className={classes.toolbar}>
      <SaveButton
        variant="contained"
        saving={saving}
        transform={transformBeforeSubmit}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        label="drivers.actions.save"
        redirect={false}
        submitOnEnter={false}
        disabled={invalid || pristine}
      />
    </div>
  );
};

const DriverCreate = (props) => {
  const history = useHistory();
  const translate = useTranslate();
  const notify = useNotify();
  const permissions = new PermissionUtils(usePermissions());
  const posthog = usePostHog();
  const isFeatureFlagEnabled = useFeatureFlagEnabled('new-driver-create-form');
  const onFailure = (err) => {
    if (err.status === 409) {
      notify('pages.driverCreate.error', 'warning');
    } else {
      notify('pages.driverCreate.maxDriverReached', 'warning');
    }
  };

  const driverDefaultValues = {
    driverTypeEnum: 0,
    contactType: 0,
    licenseIntervalDays: permissions.getControlIntervalDays() || 180,
    isPkw: permissions.isPkw(),
    isLkw: permissions.isLkw(),
    fk_tenantId: (permissions.isSuperAdmin() || permissions.isTenantAdmin())
      ? permissions.getTenantId() : undefined,
  };

  useEffect(() => {
    if (posthog) {
      posthog.identify(permissions.getId());
    }
  }, [posthog, permissions]);

  if (isFeatureFlagEnabled || process.env.NODE_ENV === 'development') {
    return (
      <Box>
        <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
          <TitleBreadcrumbs
            title={translate('pages.driverCreate.title')}
          />
        </Box>
        <Create onFailure={onFailure} {...props} component={Box}>
          <FormWithRedirect
            initialValues={driverDefaultValues}
            render={(formProps) => (
              <NewDriverFormWrapper
                superTenantId={permissions.getSuperTenantId()}
                formProps={formProps}
                toolbar={<DriverCreateToolbar {...formProps} />}
              />
            )}
          />
        </Create>
      </Box>
    );
  }

  return (
    <div style={{ maxWidth: 800 }}>
      <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
        <TitleBreadcrumbs
          title={translate('pages.driverCreate.title')}
        />
        {(permissions.isGroupAdmin() && permissions.canManage()) && (
          <Button
            variant="contained"
            component="label"
            color="primary"
            onClick={() => history.push('/drivers-upload')}
          >
            {translate('pages.driverUpload.title')}
          </Button>
        )}

      </Box>
      <Create onFailure={onFailure} {...props}>
        <FormWithRedirect
          initialValues={driverDefaultValues}
          render={(formProps) => (
            <DriverForm
              formProps={formProps}
              toolbar={<DriverCreateToolbar {...formProps} />}
            />
          )}
        />
      </Create>
    </div>
  );
};

export default DriverCreate;
