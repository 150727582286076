import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTranslate } from 'react-admin';
import Box from '@material-ui/core/Box';
import Zoom from 'react-medium-image-zoom';
import { formatIsoDateTime } from '../common/dateUtils';
import 'react-medium-image-zoom/dist/styles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.paper,
    overflowY: 'scroll',
    minHeight: '500px',
  },
  imageList: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  imageItemContainer: {
    display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 20,
  },
  imageItem: {
    maxWidth: 250,
    maxHeight: 350,
    objectFit: 'scale-down',
    cursor: 'pointer',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: '5px',
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
}));

const DocumentImageList = ({ documents }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const validDocs = documents.filter((d) => d.base64Image);

  return (
    <div className={classes.root}>

      <Box className={classes.imageList}>
        {validDocs.filter((d) => d.name.includes('front')).map((item) => (
          <Box
            key={item.id}
            className={classes.imageItemContainer}
          >
            <ListSubheader component="div">{translate('photoControls.frontPics')}</ListSubheader>
            <Zoom>
              <img
                src={`data:image/jpg;base64,${item.base64Image}`}
                alt={item.name}
                className={classes.imageItem}
              />
            </Zoom>
            <span>{formatIsoDateTime(item.createdAt)}</span>
          </Box>
        ))}
      </Box>

      <Box className={classes.imageList}>
        {validDocs.filter((d) => d.name.includes('back')).map((item) => (
          <Box
            key={item.id}
            className={classes.imageItemContainer}
          >
            <ListSubheader component="div">{translate('photoControls.backPics')}</ListSubheader>
            <Zoom>
              <img
                src={`data:image/jpg;base64,${item.base64Image}`}
                alt={item.name}
                className={classes.imageItem}
              />
            </Zoom>
            <span>{formatIsoDateTime(item.createdAt)}</span>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default DocumentImageList;
