import { createTheme } from '@material-ui/core/styles';

// eslint-disable-next-line import/prefer-default-export
export const newTheme = createTheme({
  palette: {
    primary: {
      main: '#12398a',
    },
    secondary: {
      main: '#abe1a4',
    },
    error: {
      main: '#DF021B',
    },
  },
  typography: {
    fontFamily: 'Helvetica Neue, Helvetica, Arial',
    fontSize: 14,
    h1: {
      fontSize: 44,
      fontWeight: 'bold',
      color: '#00368e',
    },
    h2: {
      fontSize: 38,
      color: '#00368e',
    },
    h3: {
      color: '#00368e',
      fontSize: 22,
      fontWeight: 600,
    },
    h4: {
      color: 'black',
      fontSize: 16,
      fontWeight: 500,
    },
    h5: {
      color: '#00368e',
      fontSize: 14,
      fontWeight: 400,
    },
    h6: {
      color: 'black',
      fontSize: 14,
      fontWeight: 600,
    },
    subtitle1: {
      color: '#00368e',
      fontSize: 16,
      fontWeight: 600,
    },
    subtitle2: {
      color: '#9ba0b1',
      fontSize: 14,
    },
    body1: {
      fontSize: 14,
    },
  },
  props: {
    MuiCard: {
      elevation: 0,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonBase: {
      disableTouchRipple: true,
    },
  },
  overrides: {
    MuiAlert: {
      root: {
        borderRadius: '16px',
        padding: '24px',
      },
    },
    MuiCard: {
      root: {
        borderRadius: '16px',
        padding: '24px',
        boxShadow: '3px 3px 12px 0px rgba(24 25 253 / 12%)',

      },
    },
    MuiFormHelperText: {
      root: {
        color: '#00368e',

      },
    },
    MuiButton: {
      contained: {
        borderRadius: '26px',
        minHeight: '52px',
        textTransform: 'none',
        fontWeight: 700,
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    },
    MuiInput: {
      underline: {
        background: '#aadd11',
        '&::before': {
          display: 'none',
        },
        '&::after': {
          display: 'none',
        },
        '&:focus-within': {
          background: '#21ac32',
        },
      },
    },
  },
});
