import React from 'react';
import {
  Edit, FormWithRedirect, SaveButton, useTranslate,
} from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import BusinessUnitForm from './BusinessUnitForm';

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: 'inherit',
  },
}));

const BUEditToolbar = (props) => {
  const classes = useStyles();
  const {
    invalid, pristine, saving, handleSubmitWithRedirect,
  } = props;
  return (
    <div className={classes.toolbar}>
      <SaveButton
        variant="contained"
        saving={saving}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        label="businessUnits.actions.save"
        redirect={false}
        submitOnEnter={false}
        disabled={invalid || pristine}
      />
    </div>
  );
};

const BusinessUnitEdit = (props) => {
  const translate = useTranslate();

  return (
    <div style={{ maxWidth: 600 }}>
      <TitleBreadcrumbs
        path={[
          { label: translate('pages.admin.title'), to: 'businessUnits' },
        ]}
        title={translate('businessUnits.actions.edit')}
      />
      <Edit {...props}>
        <FormWithRedirect
          render={(formProps) => (
            <BusinessUnitForm
              formProps={formProps}
              toolbar={<BUEditToolbar {...formProps} />}
            />
          )}
        />
      </Edit>
    </div>
  );
};

export default BusinessUnitEdit;
