import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useTypographyStyles = makeStyles({
  root: {
    marginBottom: 10,
    width: '100%',
  },

});

// remove props injected by SimpleForm
const FormSubtitle = ({
  basePath,
  variant,
  ...rest
}) => {
  const classes = useTypographyStyles();
  return (
    <Typography
      classes={classes}
      variant="subtitle1"
      {...rest}
    />
  );
};

export default FormSubtitle;
