import { useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import React, { forwardRef } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { formatIsoDate } from '../common/dateUtils';
import TenantListPanel from '../superTenant/TenantListPanel';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.default,
    padding: theme.spacing(2),
  },
}));

const GroupPanel = ({ record }) => {
  const translate = useTranslate();
  const history = useHistory();
  const classes = useStyles();

  const handleRowClick = (data) => {
    history.push(`/groups/${data.id}`);
  };

  const handleTenantRowClick = (data) => {
    history.push(`/tenants/${data.id}`);
  };

  return (
    <Box className={classes.container}>

      {record.Groups && (
        <>
          <Typography
            style={{ margin: '10px' }}
            variant="subtitle1"
          >
            {translate('groups.plural')}
          </Typography>
          <MaterialTable
            icons={{
              // eslint-disable-next-line react/display-name
              DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            }}
            title={null}
            style={{
              padding: 0,
              margin: 0,
              width: '100%',
            }}
            onRowClick={(_, data) => handleRowClick(data)}
            columns={[
              { title: translate('groups.fields.name'), field: 'name' },
              {
                title: translate('groups.fields.createdAt'),
                field: 'createdAt',
                type: 'date',
                render: (data) => formatIsoDate(data.createdAt),
              },
            ]}
            data={record.Groups}
            detailPanel={(rowData) => <TenantListPanel record={rowData} />}
            options={{
              showEmptyDataSourceMessage: false,
              toolbar: false,
              search: false,
              paging: false,
              filtering: false,
              header: false,
              exportButton: false,
            }}
          />
        </>
      )}

      {record.Tenants && (
        <>
          <Typography
            style={{ margin: '10px' }}
            variant="subtitle1"
          >
            {translate('superTenants.sections.withoutGroup')}
          </Typography>
          <MaterialTable
            title={null}
            style={{
              padding: 0,
              margin: 0,
              width: '100%',
            }}
            onRowClick={(_, data) => handleTenantRowClick(data)}
            columns={[
              { title: translate('tenants.fields.name'), field: 'name' },
              {
                title: translate('tenants.fields.createdAt'),
                field: 'createdAt',
                type: 'date',
                render: (data) => formatIsoDate(data.createdAt),
              },
            ]}
            data={record.Tenants}
            options={{
              showEmptyDataSourceMessage: false,
              toolbar: false,
              search: false,
              paging: false,
              filtering: false,
              header: false,
              exportButton: false,
            }}
          />
        </>
      )}

    </Box>
  );
};

export default GroupPanel;
