import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import {
  CardContent,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import styled from '@material-ui/core/styles/styled';
import { Alert, AlertTitle } from '@material-ui/lab';
import { usePostHog } from 'posthog-js/react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserPermissionsProvider } from './user-permissions-context';
import { useTenants } from './hooks';
import { newTheme } from './theme';
import {
  FormSectionContactInfo,
  FormSectionLicense,
  FormSectionVehicle,
  FormSectionComment,
} from './form-sections';
import { baseApi } from '../common/envUtils';
import axiosRequest from '../providers/axiosRequest';

/**
 * An adjusted version of the driver form, to test a new UI and UX for future improvements.
 *
 * @returns {JSX.Element}
 */

const StyledLink = styled(Button)({
  display: 'inline-flex',
  alignItems: 'center',
  textDecoration: 'underline',
  textTransform: 'none',
});

const StyledIcon = styled(ArrowForwardIosIcon)({
  marginLeft: '4px', // Adjust as needed
  fontSize: '14px', // Set icon size to match the text size
});

const StyledText = styled('span')({
  fontSize: '14px', // Set text size to match the icon size
  fontWeight: 'bold',
});

export function NewDriverForm({ tenants }) {
  const { current, all } = tenants;
  const currentTenant = useMemo(
    () => all.find((tenant) => tenant.id === current),
    [current, all],
  );
  const translate = useTranslate();
  const methods = useForm({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      tenant: current, contactType: '0', driverTypeEnum: '0', licenseIntervalDays: currentTenant?.controlIntervalDays || 180,
    },
  });
  const posthog = usePostHog();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [newDriverId, setNewDriverId] = useState('');
  const [FailureMessage, setFailureMessage] = useState('');

  function handleSubmit(data) {
    const {
      email,
      phoneNumber,
      licenseExpiryDate,
      ...rest
    } = data;
    let licenseExpiryDateUSFormat;
    if (licenseExpiryDate) {
      const [day, month, year] = licenseExpiryDate.split('/');
      licenseExpiryDateUSFormat = [month, day, year].join('/');
    }
    const dataPost = {
      licenseType: 0,
      ...rest,
      ...(data.contactType === '0' ? { email } : { phoneNumber }),
      ...{ licenseExpiryDate: licenseExpiryDateUSFormat },
    };
    axiosRequest(`${baseApi}/drivers`, {
      method: 'POST',
      body: dataPost,
    })
      .then((response) => {
        setNewDriverId(response.id);
        setIsSuccess(true);
        setIsFailure(false);
        if (posthog) {
          posthog.capture('success_driver_added');
        }
      })
      .catch((error) => {
        setIsSuccess(false);
        setIsFailure(true);
        if (error.response && error.response.status === 409) {
          setFailureMessage('pages.driverCreate.error');
        } else {
          setFailureMessage('drivers.driverForm.errorMessage.title');
        }
      })
      .finally(() => {
        window.scrollTo(0, 0);
      });
  }

  return (
    <ThemeProvider theme={newTheme}>
      <Box
        sx={{ marginBottom: isSuccess || isFailure ? '1rem' : '0' }}
      >
        {isSuccess && <NewDriverSuccess newDriverId={newDriverId} />}
        {isFailure && (
        <Alert severity="error">
          <AlertTitle>
            {
            translate(FailureMessage)
            }
          </AlertTitle>
        </Alert>
        )}
      </Box>
      <FormProvider {...methods}>
        <UserPermissionsProvider>
          <Box
            noValidate
            component="form"
            onSubmit={methods.handleSubmit(handleSubmit)}
            sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
          >
            <FormSectionContactInfo tenants={tenants} />
            <FormSectionLicense />
            <FormSectionVehicle />
            <FormSectionComment />
            <Box sx={{ pt: 2 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    disabled={methods.formState.isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={methods.formState.isSubmitting ? <CircularProgress /> : <SaveIcon />}
                  >
                    {translate('drivers.actions.save')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </UserPermissionsProvider>
      </FormProvider>
    </ThemeProvider>
  );
}

/**
 * Wrapper component for the NewDriverForm that provides tenant information.
 *
 * @function NewDriverFormWrapper
 * @param {Object} props - The props to pass down to the NewDriverForm component.
 * @returns {React.ReactElement | null} The NewDriverForm component with tenant information or null
 *     if tenant data is not available.
 *
 *
 * @description
 * This component wraps the `NewDriverForm` component and provides it with tenant information.
 * It uses the `useTenants` hook to fetch the current tenant and all tenant data.
 * If tenant data is not available (`current` or `all` is null), it returns null.
 * Otherwise, it renders the `NewDriverForm` component with the tenant data passed in as props.
 */
export function NewDriverFormWrapper(props) {
  const { current, all } = useTenants(props.superTenantId);
  if (!current || !all) {
    return null;
  }

  return (
    <Container>
      <NewDriverForm {...props} tenants={{ current, all }} />
    </Container>
  );
}

const useStyles = makeStyles({
  successRoot: {
    position: 'relative',
  },
  successMessage: {
    width: '100%',
    flex: 1,
  },
});

export function NewDriverSuccess({ newDriverId }) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  const translate = useTranslate();
  const classes = useStyles();
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(true);
  }, [newDriverId]);

  const handleOnClickDriversOverview = () => {
    history.push('/drivers');
  };
  const handleOnClickDriverProfile = () => {
    history.push(`/drivers/${newDriverId}/show`);
  };

  return isOpen && (
  <Alert severity="info" icon={<span />} classes={{ root: classes.successRoot, message: classes.successMessage }}>
    <IconButton
      aria-label="close"
      onClick={handleClose}
      style={{
        position: 'absolute',
        top: 8,
        right: 8,
      }}
    >
      <CancelIcon color="primary" />
    </IconButton>
    <CardContent style={{ padding: '0px' }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography gutterBottom variant="subtitle1" component="h3">
          {translate('drivers.driverForm.successMessage.title')}
        </Typography>
        <Typography gutterBottom variant="body1" component="h4">
          {translate('drivers.driverForm.successMessage.copy')}
        </Typography>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            marginTop: 16,
          }}
        >
          <StyledLink color="primary" onClick={handleOnClickDriverProfile}>
            <StyledText>{translate('drivers.driverForm.successMessage.links.detailView')}</StyledText>
            <StyledIcon />
          </StyledLink>
          <StyledLink color="primary" onClick={handleOnClickDriversOverview}>
            <StyledText>{translate('drivers.driverForm.successMessage.links.overView')}</StyledText>
            <StyledIcon />
          </StyledLink>
        </Box>
      </Box>
    </CardContent>
  </Alert>
  );
}
