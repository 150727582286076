import { Controller, useFormContext } from 'react-hook-form';
import {
  choices, email, useTranslate,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import {
  CardContent, Grid, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import Checkbox from '@material-ui/core/Checkbox';
import EventIcon from '@material-ui/icons/Event';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { isAfter, isDate } from 'date-fns';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import { FormTextField } from './form-fields';
import { getTenantFilter, isDateValid } from './utils';
import { useUserPermissionsContext } from './user-permissions-context';
import { newTheme } from './theme';
import httpClient from '../providers/httpClient';
import { baseApi } from '../common/envUtils';

const useStyles = makeStyles({
  icon: {
    marginRight: '8px',
  },
  licenseCheckLabel: {
    alignItems: 'flex-start',
  },
  licenseCheckRadio: {
    marginTop: '-0.5rem',
  },
});

export function FormSectionContactInfo({ tenants: { all: tenants, current: currentId } }) {
  const methods = useFormContext();
  const translate = useTranslate();
  const classes = useStyles();

  const { permissions } = useUserPermissionsContext();

  const hasAccessToTenant = (permissions.isSuperAdmin()
    || (permissions.getSuperTenantId() && permissions.isTenantAdmin())
    || (permissions.getGroupId() && permissions.isGroupAdmin())
  );

  const tenantFilter = getTenantFilter(permissions);
  const hasUvv = permissions.isIncludeUvv();

  const contactType = methods.watch('contactType');
  const filteredTenants = tenants
  // we check the super tenant id, if not set we compare the tenant with the current id
    .filter(
      (tenant) => tenant.SuperTenant?.id === tenantFilter?.fk_superTenantId
     || tenant.id === currentId,
    );
  return (
    <Card>
      <Typography gutterBottom variant="subtitle1" component="h3">
        {translate('drivers.driverForm.contactSection.title')}
      </Typography>
      <Typography gutterBottom variant="h5" component="h4">
        {hasUvv ? translate('drivers.driverForm.contactSection.copy.withUvv')
          : translate('drivers.driverForm.contactSection.copy.default')}
      </Typography>
      <Box sx={{ pt: 2 }}>
        {hasAccessToTenant && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormTextField
                select
                defaultValue={methods.formState.defaultValues.tenant}
                id="form:tenant"
                name="tenant"
                label="drivers.driverForm.fields.department.label"
                helperText={filteredTenants.length < 2 ? 'drivers.driverForm.fields.department.hint.disabled' : undefined}
                options={{
                  required: true,
                }}
              >
                {filteredTenants
                  .map(({
                    id,
                    name,
                  }) => (
                    <MenuItem
                      key={id}
                      value={id}
                    >
                      {name}
                    </MenuItem>
                  ))}
              </FormTextField>

            </Grid>
          </Grid>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormTextField
              id="form:firstName"
              name="firstName"
              label="drivers.driverForm.fields.firstName.label"
              options={{
                required: translate('drivers.driverForm.fields.firstName.error.required'),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              id="form:lastName"
              name="surName"
              label="drivers.driverForm.fields.surName.label"
              options={{
                required: translate('drivers.driverForm.fields.surName.error.required'),
              }}
            />
          </Grid>
          {permissions.isAllowSms() ? (
            <>
              <Grid item xs={12}>
                <Controller
                  control={methods.control}
                  name="contactType"
                  render={({ field }) => (
                    <RadioGroup
                      aria-label={translate('drivers.contactType.label')}
                      {...field}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio color="primary" />}
                        label={translate('drivers.driverForm.fields.contactOptions.options.email')}
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label={translate('drivers.driverForm.fields.contactOptions.options.sms')}
                      />
                    </RadioGroup>
                  )}

                />

              </Grid>

              <Grid item xs={12} md={6}>
                {contactType === '0' && (
                <FormTextField
                  InputProps={{ startAdornment: <EmailIcon color="primary" className={classes.icon} /> }}
                  label="drivers.driverForm.fields.email.label"
                  name="email"
                  options={{
                    required: translate('drivers.driverForm.fields.email.error.required'),
                    validate(value) {
                      const checkEmail = email();
                      return !checkEmail(value) || translate('drivers.driverForm.fields.email.error.invalid');
                    },
                  }}
                />
                )}
                {contactType === '1' && (
                <FormTextField
                  InputProps={{ startAdornment: <PhoneIcon color="primary" className={classes.icon} /> }}
                  label="drivers.driverForm.fields.mobilePhone.label"
                  hintText="drivers.driverForm.fields.mobilePhone.hint"
                  name="phoneNumber"
                  options={{
                    required: translate('drivers.driverForm.fields.mobilePhone.error.required'),
                  }}
                />
                )}
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={6}>
              <FormTextField
                InputProps={{ startAdornment: <EmailIcon color="primary" className={classes.icon} /> }}
                label="drivers.driverForm.fields.email.label"
                name="email"
                options={{
                  required: translate('drivers.driverForm.fields.email.error.required'),
                  validate(value) {
                    const checkEmail = email();
                    return !checkEmail(value) || translate('drivers.driverForm.fields.email.error.invalid');
                  },
                }}
              />
            </Grid>
          )}

        </Grid>
      </Box>
    </Card>
  );
}

export function FormSectionVehicle() {
  const translate = useTranslate();
  const { permissions } = useUserPermissionsContext();
  const methods = useFormContext();

  return (permissions.isLkw() && permissions.isPkw()) && (
    <Card>
      <Typography gutterBottom variant="subtitle1" component="h3">
        {translate('drivers.driverForm.uvvSection.title')}
      </Typography>
      <Typography gutterBottom variant="h5" component="h4">
        {translate('drivers.driverForm.uvvSection.copy')}
      </Typography>
      <Box sx={{ pt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box
              component="label"
              sx={{
                display: 'block',
                pl: 5,
                pr: 12,
                py: 2,
                boxShadow: `0 0 0 1px ${newTheme.palette.grey['300']}`,
                borderRadius: '16px',
                position: 'relative',
              }}
            >
              <Box sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 96,
                bgcolor: newTheme.palette.grey['200'],
                borderTopRightRadius: '16px',
                borderBottomRightRadius: '16px',
              }}
              >

                <SvgIcon
                  style={{ width: 48, height: 48 }}
                >
                  <path
                    fill={newTheme.palette.primary.main}
                    d="M18,18.5A1.5,1.5 0 0,1 16.5,17A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 19.5,17A1.5,1.5 0 0,1 18,18.5M19.5,9.5L21.46,12H17V9.5M6,18.5A1.5,1.5 0 0,1 4.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,17A1.5,1.5 0 0,1 6,18.5M20,8H17V4H3C1.89,4 1,4.89 1,6V17H3A3,3 0 0,0 6,20A3,3 0 0,0 9,17H15A3,3 0 0,0 18,20A3,3 0 0,0 21,17H23V12L20,8Z"
                  />

                </SvgIcon>

              </Box>
              <Controller
                control={methods.control}
                name="isLkw"
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Checkbox color="primary" />}
                    label={translate('drivers.fields.isLkw')}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              component="label"
              sx={{
                display: 'block',
                pl: 5,
                pr: 12,
                py: 2,
                boxShadow: `0 0 0 1px ${newTheme.palette.grey['300']}`,
                borderRadius: '16px',
                position: 'relative',
              }}
            >
              <Box sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 96,
                bgcolor: newTheme.palette.grey['200'],
                borderTopRightRadius: '16px',
                borderBottomRightRadius: '16px',
              }}
              >
                <SvgIcon
                  style={{ width: 48, height: 48 }}
                >
                  <path
                    fill={newTheme.palette.primary.main}
                    d="M16,6L19,10H21C22.11,10 23,10.89 23,12V15H21A3,3 0 0,1 18,18A3,3 0 0,1 15,15H9A3,3 0 0,1 6,18A3,3 0 0,1 3,15H1V12C1,10.89 1.89,10 3,10L6,6H16M10.5,7.5H6.75L4.86,10H10.5V7.5M12,7.5V10H17.14L15.25,7.5H12M6,13.5A1.5,1.5 0 0,0 4.5,15A1.5,1.5 0 0,0 6,16.5A1.5,1.5 0 0,0 7.5,15A1.5,1.5 0 0,0 6,13.5M18,13.5A1.5,1.5 0 0,0 16.5,15A1.5,1.5 0 0,0 18,16.5A1.5,1.5 0 0,0 19.5,15A1.5,1.5 0 0,0 18,13.5Z"
                  />
                </SvgIcon>

              </Box>
              <Controller
                control={methods.control}
                name="isPkw"
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    control={<Checkbox color="primary" />}
                    label={translate('drivers.fields.isPkw')}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>

  );
}

export function FormSectionLicense() {
  const translate = useTranslate();

  const [codes, setCodes] = useState([]);
  const { controller, watch } = useFormContext();
  const classes = useStyles();

  const validateLabelCode = choices(codes, translate('pages.driverEdit.codeHint'));

  const driverTypeEnum = watch('driverTypeEnum');
  const isDigitalCheck = driverTypeEnum === '0';

  useEffect(() => {
    httpClient(`${baseApi}/label-codes`)
      .then(({ json }) => {
        setCodes(json);
      });
  }, []);

  return (
    <Card>
      <Typography gutterBottom variant="subtitle1" component="h3">
        {translate('drivers.driverForm.licenseCheckSection.title')}
      </Typography>
      <Typography gutterBottom variant="body1" component="h4">
        {translate('drivers.driverForm.licenseCheckSection.copy')}
      </Typography>
      <Controller
        name="driverTypeEnum"
        control={controller}
        render={({ field }) => (
          <RadioGroup
            {...field}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 32,
            }}
          >
            <CardContent style={{
              boxShadow: `0 0 0 1px ${isDigitalCheck ? newTheme.palette.primary.main : newTheme.palette.grey['300']}`,
              borderRadius: '16px',
              position: 'relative',
              overflow: 'hidden',
            }}
            >
              <Box sx={{ mb: 1.5 }}>
                <FormControlLabel
                  classes={{ root: classes.licenseCheckLabel }}
                  value={0}
                  control={<Radio color="primary" checked={isDigitalCheck} className={classes.licenseCheckRadio} />}
                  label={(
                    <>
                      <Typography gutterBottom variant="h3" component="h3">
                        {translate('drivers.driverForm.licenseCheckSection.options.digital.title')}
                      </Typography>
                      <Typography gutterBottom variant="h5" component="h4">
                        {translate('drivers.driverForm.licenseCheckSection.options.digital.copy')}
                      </Typography>
                    </>
            )}
                />
              </Box>

              {isDigitalCheck && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    render={({ field: intervalField }) => (
                      <TextField
                        select
                        fullWidth
                        variant="outlined"
                        disabled={!isDigitalCheck}
                        id="form:licenseInterval"
                        label={translate('drivers.driverForm.fields.interval.label')}
                        helperText={translate('drivers.driverForm.fields.interval.hint')}
                        {...intervalField}

                      >
                        {[1, 3, 6].map((val) => ({
                          id: val * 30,
                          name: `${val} ${translate('drivers.fields.licenseIntervalPostfix')}`,
                        }))
                          .map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.id}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                    name="licenseIntervalDays"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormTextField
                    disabled={!isDigitalCheck}
                    id="form:licenseLabelCode"
                    name="licenseLabelCode"
                    label="drivers.driverForm.fields.labelCode.label"
                    helperText="drivers.driverForm.fields.labelCode.hint"
                    options={{
                      required: translate('drivers.driverForm.fields.labelCode.error.required'),
                      validate(value) {
                        return !validateLabelCode(value) || translate('drivers.driverForm.fields.labelCode.error.invalid');
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormTextField
                    InputProps={{ startAdornment: <EventIcon color="primary" className={classes.icon} /> }}
                    disabled={!isDigitalCheck}
                    type="text"
                    id="form:licenseExpiryDate"
                    name="licenseExpiryDate"
                    label="drivers.driverForm.fields.licenseExpiryDate.label"
                    helperText="drivers.driverForm.fields.licenseExpiryDate.hint"
                    options={{
                      required: false,
                      validate(value) {
                        if (!value) {
                          return true;
                        }
                        const [day, month, year] = value.split('/').map((stringValue) => Number.parseInt(stringValue, 10));
                        const date = new Date(year, month - 1, day);
                        return (isDateValid(day, month, year) && isDate(date) && isAfter(date, new Date())) || translate('drivers.driverForm.fields.licenseExpiryDate.error.invalid');
                      },
                    }}
                  />
                </Grid>
              </Grid>
              )}
            </CardContent>
            <CardContent style={{
              boxShadow: `0 0 0 1px ${!isDigitalCheck ? newTheme.palette.primary.main : newTheme.palette.grey['300']}`,
              borderRadius: '16px',
              position: 'relative',
              overflow: 'hidden',
            }}
            >
              <Box sx={{ mb: 1.5 }}>
                <FormControlLabel
                  classes={{ root: classes.licenseCheckLabel }}
                  value={1}
                  control={<Radio color="primary" checked={!isDigitalCheck} className={classes.licenseCheckRadio} />}
                  label={(
                    <>
                      <Typography gutterBottom variant="h3" component="h3">
                        {translate('drivers.driverForm.licenseCheckSection.options.manual.title')}
                      </Typography>
                      <Typography gutterBottom variant="h5" component="h4">
                        {translate('drivers.driverForm.licenseCheckSection.options.manual.copy')}
                      </Typography>
                    </>
            )}
                />
              </Box>
            </CardContent>
          </RadioGroup>
        )}
      />
    </Card>
  );
}

export function FormSectionComment() {
  const translate = useTranslate();
  const { permissions } = useUserPermissionsContext();
  const [hasCustomCommentField1, hasCustomCommentField2] = permissions.hasCustomCommentField();

  return (
    <Card>
      <Typography gutterBottom variant="subtitle1" component="h3">
        {translate('drivers.driverForm.commentSection.title')}
      </Typography>
      <Typography gutterBottom variant="body1" component="h4">
        {translate('drivers.driverForm.commentSection.copy')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormTextField
            id="form:licenseComment1"
            name="licenseComment1"
            label={permissions.getCommentField1Name()}
            helperText={hasCustomCommentField1 ? undefined : 'drivers.fields.helperText.licenseComment1'}
          />
        </Grid>
        {permissions.getCommentField2Name() && (
        <Grid item xs={12} md={6}>
          <FormTextField
            id="form:licenseComment2"
            name="licenseComment2"
            label={permissions.getCommentField2Name()}
            helperText={hasCustomCommentField2 ? undefined : 'drivers.fields.helperText.licenseComment2'}
          />
        </Grid>
        )}
      </Grid>
    </Card>
  );
}
