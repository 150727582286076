import { createTheme, makeStyles } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#e4eaf4',
      main: '#00368e',
    },
    secondary: {
      main: '#fa0000',
    },
    white: {
      main: '#ffffff',
    },
    gray: {
      light: '#d8dae0',
      main: '#9ba0b1',
    },
    green: {
      light: '#EBF4EB',
      main: '#81c784',
    },
    red: {
      light: '#F7E5DF',
      main: '#e64a19',
    },
    yellow: {
      light: '#FAF5E4',
      main: '#ffd740',
    },
    text: {
      disabled: '#dcdee6',
      secondary: '#9ba0b1',
    },
    background: {
      default: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Helvetica Neue, Helvetica, Arial',
    fontSize: 14,
    h1: {
      fontSize: 44,
      fontWeight: 'bold',
      color: '#00368e',
    },
    h2: {
      fontSize: 38,
      color: '#00368e',
    },
    h3: {
      color: '#00368e',
      fontSize: 22,
      fontWeight: 600,
    },
    h4: {
      color: 'black',
      fontSize: 16,
      fontWeight: 500,
    },
    h6: {
      color: 'black',
      fontSize: 14,
      fontWeight: 600,
    },
    subtitle1: {
      color: '#00368e',
      fontSize: 14,
      fontWeight: 600,
    },
    subtitle2: {
      color: '#9ba0b1',
      fontSize: 14,
    },
    body1: {
      fontSize: 14,
    },
  },
  overrides: {
    MuiButton: {
      label: {
        width: 'max-content',
      },
      text: {
        textTransform: 'none',
        fontFamily: 'Helvetica Neue, Helvetica, Arial',
        fontSize: 14,
        fontWeight: 600,
      },
      outlined: {
        textTransform: 'none',
        fontFamily: 'Helvetica Neue, Helvetica, Arial',
        fontSize: 14,
        fontWeight: 600,
        background: '#e4eaf4',
        border: '0 !important',
      },
      contained: {
        textTransform: 'none',
        fontFamily: 'Helvetica Neue, Helvetica, Arial',
        fontSize: 14,
        fontWeight: 600,
        color: 'white',
        padding: '16px',
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
      },
    },
    MuiTableHead: {
      root: {
        fontWeight: 600,
        padding: 12,
      },
    },
    MuiTableCell: {
      root: {
        height: 44,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    RaButton: {
      button: {
        fontFamily: 'Helvetica Neue, Helvetica, Arial',
        fontSize: 14,
        fontWeight: 600,
        padding: '16px',
        minWidth: 'unset',
      },
      label: {
        paddingLeft: 0,
      },
    },
    RaListToolbar: {
      actions: {
        paddingBottom: 36,
      },
    },
    RaTopToolbar: {
      root: {
        paddingTop: 'unset',
      },
    },
    MuiFormControlLabel: {
      label: {
        color: 'black !important',
      },
    },
  },
});

export default theme;

export const useOutlinedInputStyles = makeStyles(() => ({
  root: {
    height: 41,
    color: theme.palette.gray.main,
    backgroundColor: 'white',
    '& $notchedOutline': {
      borderColor: theme.palette.primary.light,
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {
    color: theme.palette.primary.main,
  },
  notchedOutline: {},
}));
