import React from 'react';
import { useTranslate } from 'react-admin';
import Modal from '@material-ui/core/Modal';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  container: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80%',
    width: '80%',
    position: 'absolute',
    top: '10%',
    left: '10%',
  },
});

const PdfPreviewModal = ({
  open, onClose, document, fileName,
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={classes.container}>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <PDFViewer height="95%">
          {document}
        </PDFViewer>

        <PDFDownloadLink
          document={document}
          fileName={`${fileName}.pdf`}
          style={{
            textDecoration: 'none',
            textAlign: 'center',
            backgroundColor: '#00368e',
            fontSize: 14,
            fontWeight: 600,
            color: 'white',
            padding: '16px',
          }}
        >
          {({
            loading, error,
            // eslint-disable-next-line no-nested-ternary
          }) => (!error ? (loading ? translate('ra.message.loading')
            : translate('common.download')) : error)}
        </PDFDownloadLink>
      </Paper>
    </Modal>
  );
};

export default PdfPreviewModal;
