import {
  Datagrid, List, TextField, usePermissions, useTranslate,
} from 'react-admin';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TenantGroupBatchMove from './TenantGroupBatchMove';
import BoolField from '../components/table/BoolField';
import DateField from '../components/table/DateField';
import PermissionUtils from '../common/permissionUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.gray.light,
    padding: theme.spacing(4, 10),
  },
}));

const initTableProps = {
  basePath: '/tenants',
  hasCreate: false,
  hasEdit: true,
  hasList: true,
  hasShow: false,
  location: {
    pathname: '/', search: '', hash: '', state: undefined,
  },
  match: {
    path: '/', url: '/', isExact: true, params: {},
  },
  sort: { field: 'createdAt', order: 'DESC' },
  options: {},
  permissions: null,
  resource: 'tenants',
  perPage: 500,
};

const TenantListPanel = ({ record }) => {
  const translate = useTranslate();
  const permissions = new PermissionUtils(usePermissions());
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography
        style={{ margin: '10px' }}
        variant="subtitle1"
      >
        {translate('groups.fields.tenants')}
      </Typography>
      <List
        {...initTableProps}
        filterDefaultValues={{ fk_superTenantId: record.fk_superTenantId, fk_groupId: record.id }}
        pagination={null}
        bulkActionButtons={permissions.canManage() ? <TenantGroupBatchMove /> : false}
        exporter={false}
        actions={null}
      >
        <Datagrid rowClick="edit">
          <TextField source="name" label="tenants.fields.name" />
          <BoolField source="active" label="tenants.fields.active" />
          <DateField source="createdAt" label="tenants.fields.createdAt" />
        </Datagrid>
      </List>
    </Box>
  );
};

export default TenantListPanel;
