import React from 'react';
import {
  AutocompleteInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  usePermissions,
  useTranslate,
} from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormSubtitle from '../components/form/FormSubtitle';
import PermissionUtils from '../common/permissionUtils';
import FormCheckbox from '../components/form/FormCheckbox';

const Accordion = withStyles({
  root: {
    border: 'none',
    marginBottom: '14px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionSummary);

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 40px 30px 40px',
    flexDirection: 'row',
    width: '100%',
    maxWidth: 550,
    flexWrap: 'wrap',
  },
  halfWidth: {
    flexBasis: '48%',
    width: '48%',
  },
}));

const TenantForm = ({ toolbar }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const permissions = new PermissionUtils(usePermissions());

  const customUvvs = [
    { id: '52', name: translate('tenants.uvvs.52') },
    { id: '45', name: translate('tenants.uvvs.45') },
    { id: '40', name: translate('tenants.uvvs.40') },
    { id: '7', name: translate('tenants.uvvs.7') },
    { id: '8', name: translate('tenants.uvvs.8') },
    { id: '29', name: translate('tenants.uvvs.29') },
    { id: '31', name: translate('tenants.uvvs.31') },
    { id: '30', name: translate('tenants.uvvs.30') },
    { id: '32', name: translate('tenants.uvvs.32') },
    { id: '33', name: translate('tenants.uvvs.33') },
    { id: '43', name: translate('tenants.uvvs.43') },
    { id: '62', name: translate('tenants.uvvs.62') },
    { id: '3', name: 'Testkurs' },
    { id: '2', name: translate('tenants.uvvs.2') },
  ];

  return (
    <form className={classes.container}>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
      >

        <Box display="flex" flexDirection="column" width="100%">
          {permissions.isSuperAdmin() && (
            <Box display="flex" flexDirection="column">
              <ReferenceInput
                label="tenants.fields.superTenant"
                source="fk_superTenantId"
                reference="super-tenants"
                allowEmpty
              >
                <AutocompleteInput
                  fullWidth
                  margin="none"
                  variant="outlined"
                  optionText="name"
                  allowEmpty
                />
              </ReferenceInput>
            </Box>

          )}

          {permissions.isTenantAdmin() && (
            <Box>
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <ReferenceInput
                    label="tenants.fields.group"
                    reference="groups"
                    source="fk_groupId"
                    filter={{ fk_superTenantId: formData.fk_superTenantId }}
                  >
                    <AutocompleteInput
                      fullWidth
                      margin="none"
                      variant="outlined"
                      optionText="name"
                      allowEmpty
                      resettable
                      clearAlwaysVisible
                      {...rest}
                    />
                  </ReferenceInput>
                )}
              </FormDataConsumer>
            </Box>
          )}
        </Box>

        <TextInput
          fullWidth
          margin="none"
          variant="outlined"
          source="name"
          label="tenants.fields.tenantName"
          validate={required()}
        />

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="tenant-form-header"
          >
            <Typography
              variant="subtitle2"
            >
              {translate('tenants.sections.additionalData')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              <TextInput
                className={classes.halfWidth}
                margin="none"
                variant="outlined"
                source="description"
                label="tenants.fields.description"
              />
              <TextInput
                className={classes.halfWidth}
                margin="none"
                variant="outlined"
                source="address"
                label="tenants.fields.address"
              />
              <TextInput
                className={classes.halfWidth}
                margin="none"
                variant="outlined"
                source="city"
                label="tenants.fields.city"
              />
              <TextInput
                className={classes.halfWidth}
                margin="none"
                variant="outlined"
                source="country"
                label="tenants.fields.country"
              />
            </Box>
          </AccordionDetails>
        </Accordion>

      </Box>

      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <FormSubtitle>{translate('tenants.sections.photo')}</FormSubtitle>
        {permissions.isSuperAdmin() && (
          <>
            <FormCheckbox
              source="hasPhotoControl"
              label="tenants.fields.hasPhotoControl"
              className={classes.halfWidth}
            />
            <FormCheckbox
              source="hasPhotoRollout"
              label="tenants.fields.hasPhotoRollout"
              className={classes.halfWidth}
            />
          </>
        )}
      </Box>

      <Box display="flex" flexDirection="column">
        <FormSubtitle>{translate('tenants.sections.settings')}</FormSubtitle>

        {permissions.isSuperAdmin() && (
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <FormCheckbox
              source="require2FA"
              label="tenants.fields.require2FA"
              className={classes.halfWidth}
            />

            <FormCheckbox
              source="allowSms"
              label="tenants.fields.allowSms"
              className={classes.halfWidth}
            />

            {permissions.isSuperAdmin() && (
              <FormCheckbox
                source="external"
                label="tenants.fields.external"
                className={classes.halfWidth}
              />
            )}

            <FormDataConsumer>
              {
                (({ formData, ...rest }) => (formData.external ? (
                  <Box>
                    <FormCheckbox
                      source="useTriggerEmail"
                      label="tenants.fields.useTriggerEmail"
                      className={classes.halfWidth}
                      disabled={!permissions.isSuperAdmin()
                        || (formData.useReminder && formData.useTriggerEmail)}
                    />
                    <FormCheckbox
                      source="useReminder"
                      label="tenants.fields.useReminder"
                      className={classes.halfWidth}
                      disabled={!permissions.isSuperAdmin()
                        || !formData.useTriggerEmail || formData.useInterval}
                    />
                    <FormCheckbox
                      source="useInterval"
                      label="tenants.fields.useInterval"
                      className={classes.halfWidth}
                      disabled={!permissions.isSuperAdmin() || !formData.useReminder}
                    />
                    <TextInput
                      source="callbackToken"
                      label="tenants.fields.callbackToken"
                      fullWidth
                      variant="outlined"
                      {...rest}
                    />
                  </Box>
                ) : <span />
                ))
              }
            </FormDataConsumer>

            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              <FormSubtitle>{translate('tenants.sections.driverPresets')}</FormSubtitle>
              <FormCheckbox
                source="isPkw"
                label="tenants.fields.isPkw"
                className={classes.halfWidth}
              />
              <FormCheckbox
                source="isLkw"
                label="tenants.fields.isLkw"
                className={classes.halfWidth}
              />
            </Box>

            <FormDataConsumer>
              {
                ({ formData, ...rest }) => (!formData.external ? (
                  <>
                    <SelectInput
                      fullWidth
                      margin="none"
                      variant="outlined"
                      source="controlIntervalDays"
                      label="tenants.fields.controlIntervalDays"
                      choices={[
                        {
                          id: 3,
                          name: `3 ${translate('drivers.fields.licenseIntervalPostfixDays')}`,
                        },
                        ...[1, 2, 3, 4, 5, 6].map((val) => ({
                          id: val * 30,
                          name: `${val} ${translate('drivers.fields.licenseIntervalPostfix')}`,
                        })),
                      ]}
                    />

                    {permissions.isSuperAdmin() && (
                      <Box width="100%" display="flex" justifyContent="space-between">
                        <NumberInput
                          className={classes.halfWidth}
                          source="maxDriverType0"
                          label="tenants.fields.driversType0"
                          variant="outlined"
                          margin="none"
                          {...rest}
                        />

                        <NumberInput
                          className={classes.halfWidth}
                          source="maxDriverType1"
                          label="tenants.fields.driversType1"
                          variant="outlined"
                          margin="none"
                          {...rest}
                        />
                      </Box>
                    )}
                  </>
                ) : <span />)
              }
            </FormDataConsumer>

            <FormCheckbox
              source="includeUVV"
              label="tenants.fields.includeUVV"
              className={classes.halfWidth}
            />

            <FormDataConsumer>
              {
                (({ formData, ...rest }) => (formData.includeUVV ? (
                  <>
                    <FormCheckbox
                      source="type1UVV"
                      label="tenants.fields.type1UVV"
                      className={classes.halfWidth}
                      {...rest}
                    />
                    <FormCheckbox
                      source="includeUVVLKW"
                      label="tenants.fields.includeUVVLKW"
                      className={classes.halfWidth}
                      {...rest}
                    />
                  </>
                ) : null))
              }
            </FormDataConsumer>
            <Box width="100%">
              <AutocompleteArrayInput
                fullWidth
                margin="none"
                variant="outlined"
                source="additionalUVVs"
                label="tenants.sections.cUvv"
                resettable
                clearAlwaysVisible
                choices={customUvvs}
              />
            </Box>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <FormSubtitle>{translate('tenants.sections.commentFieldNames')}</FormSubtitle>

          <TextInput
            className={classes.halfWidth}
            margin="none"
            variant="outlined"
            source="commentField1Name"
            label="tenants.fields.commentField1Name"
            resettable
          />
          <TextInput
            className={classes.halfWidth}
            margin="none"
            variant="outlined"
            source="commentField2Name"
            label="tenants.fields.commentField2Name"
            resettable
          />
        </Box>

      </Box>
      {toolbar}
    </form>
  );
};

export default TenantForm;
