import React, { useEffect, useState } from 'react';
import { usePermissions, useTranslate } from 'react-admin';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import { fetchMonitoring } from '../redux/statsReducer';
import DateField from '../components/DateField';
import { formatDateAsIso } from '../common/dateUtils';
import DriverByStatusChart from './charts/DriverByStatusChart';
import DriverByTypeChart from './charts/DriverByTypeChart';
import DriverByUvvChart from './charts/DriverByUvvChart';
import DailyCounterChart from './charts/DailyCounterChart';
import PermissionUtils from '../common/permissionUtils';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '26px 32px',
    width: 450,
    margin: '12px 0',
  },
  picker: {
    width: '48%',
  },
  hint: {
    color: theme.palette.text.secondary,
  },
}));

const MonitoringPage = ({ fetch, data, loading }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const permissions = new PermissionUtils(usePermissions());

  const [state, setState] = useState({ startDate: null, endDate: null });

  useEffect(() => {
    if (state.startDate) {
      fetch(state);
    }
    // eslint-disable-next-line
  }, [state])

  const handleDateChange = (name) => (date) => {
    setState({
      ...state,
      [name]: date ? formatDateAsIso(date) : null,
    });
  };

  return (
    <Box width="100%">
      <TitleBreadcrumbs title={translate('pages.monitoring.title')} />

      <Paper className={classes.card}>
        <Typography variant="subtitle1">
          {translate('pages.monitoring.range')}
        </Typography>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={2}
        >
          <DateField
            className={classes.picker}
            label={translate('common.startDate')}
            value={state.startDate}
            maxDate={state.endDate}
            onChange={handleDateChange('startDate')}
            disableFuture
          />
          <DateField
            className={classes.picker}
            label={translate('common.endDate')}
            value={state.endDate}
            minDate={state.startDate}
            onChange={handleDateChange('endDate')}
            disableFuture
            clearable
          />
        </Box>
      </Paper>

      {(!state.startDate) && (
        <Box>
          <Typography
            className={classes.hint}
            variant="h4"
          >
            {translate('pages.monitoring.hint')}
          </Typography>
        </Box>
      )}

      {(!loading && !isEmpty(data.monitoring)) && (
        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
          <Paper className={classes.card}>
            <Typography
              variant="h4"
            >
              {translate('pages.monitoring.driversByStatus.title')}
            </Typography>
            <DriverByStatusChart monitoring={data.monitoring} />
          </Paper>
          <Paper className={classes.card}>
            <Typography
              variant="h4"
            >
              {translate('pages.monitoring.driversByType.title')}
            </Typography>
            <DriverByTypeChart monitoring={data.monitoring} />
          </Paper>
          {(data.dailyCounters.length > 0) && (
            <Paper className={classes.card}>
              <Typography
                variant="h4"
              >
                {translate('pages.monitoring.dailyCounters.title')}
              </Typography>
              <DailyCounterChart dailyCounters={data.dailyCounters} />
            </Paper>
          )}
          {permissions.isIncludeUvv() && (
            <Paper className={classes.card}>
              <Typography
                variant="h4"
              >
                {translate('pages.monitoring.driversByUvv.title')}
              </Typography>
              <DriverByUvvChart monitoring={data.monitoring} />
            </Paper>
          )}
        </Box>
      )}

    </Box>
  );
};

const mapStateToProps = (state) => ({
  data: state.stats.monitoring,
  loading: state.stats.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: ({ startDate, endDate }) => {
    dispatch(fetchMonitoring(startDate, endDate));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringPage);
