import React, { useState } from 'react';
import {
  useAuthProvider,
  useLogout,
  useNotify,
  usePermissions,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SvgIcon from '@material-ui/core/SvgIcon';
import Box from '@material-ui/core/Box';
import PopoverMenu from '../components/PopoverMenu';
import { ReactComponent as ExitIcon } from '../assets/icons/exit.svg';
import { ReactComponent as SwitchIcon } from '../assets/icons/switch.svg';
import SwitchTenantModal from './SwitchTenantModal';
import PermissionUtils from '../common/permissionUtils';
import { setSettings, updateSettings } from '../redux/settingsReducer';
import LargeSwitch from '../components/LargeSwitch';

const useAppbarStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.1)',
    height: 77,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const useStyles = makeStyles((theme) => ({
  triggerContainer: {
    width: 200,
    height: 60,
    marginRight: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    cursor: 'pointer',
    borderRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: (props) => (props.menuOpen ? '0px 8px 10px 1px rgba(0,0,0,0.14)' : null),
  },
  menuIndicator: {
    color: theme.palette.gray.main,
  },
}));

const MyAppBar = ({
  globalView, resetUserSettings, updateGlobalView, ...props
}) => {
  const translate = useTranslate();
  const logout = useLogout();
  const notify = useNotify();
  const permissions = new PermissionUtils(usePermissions());
  const refresh = useRefresh();
  const authProvider = useAuthProvider();
  const appbarStyles = useAppbarStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [swiModalOpen, setSwiModal] = useState(false);
  const classes = useStyles({ menuOpen: Boolean(anchorEl) });

  const handleTenantSwitch = (tenantId) => {
    authProvider.switchTenant(tenantId).then(() => {
      notify('common.success');
      setTimeout(() => {
        window.location.reload(false);
      }, 200);
    });
  };

  const handleGlobalViewChange = () => {
    updateGlobalView(!globalView);
    refresh();
  };

  const handleLogout = () => {
    logout();
    resetUserSettings();
  };

  return (
    <AppBar {...props} classes={appbarStyles}>

      {((permissions.isSuperAdmin() || permissions.isTenantAdmin() || permissions.isGroupAdmin())
          && globalView !== null
          && globalView !== undefined)
        && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={1}>
              <Typography variant="h6">
                {translate('pages.appBar.globalView')}
              </Typography>
            </Box>
            <LargeSwitch
              id="global-switch"
              checked={globalView}
              onChange={handleGlobalViewChange}
            />
          </Box>
        )}
      <Box
        className={classes.triggerContainer}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <div>
          <Typography variant="subtitle1" id="user-name">
            {permissions.getName()}
          </Typography>
        </div>
        {anchorEl ? <KeyboardArrowUpIcon className={classes.menuIndicator} />
          : <KeyboardArrowDownIcon className={classes.menuIndicator} />}
      </Box>
      <PopoverMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        width={200}
        menuOptions={[
          {
            icon: (
              <SvgIcon><SwitchIcon /></SvgIcon>
            ),
            role: 2,
            label: translate('pages.appBar.switchTenant'),
            onClick: () => setSwiModal(true),
          },
          {
            icon: (
              <SvgIcon>
                <ExitIcon />
              </SvgIcon>),
            label: translate('ra.auth.logout'),
            onClick: handleLogout,
          },
        ].filter((o) => !o.role || (permissions.getRole() >= o.role))}
      />

      {permissions && ((permissions.isTenantAdmin() && permissions.getSuperTenantId())
          || (permissions.isGroupAdmin() && permissions.getGroupId())
          || permissions.isSuperAdmin())
        && (
          <SwitchTenantModal
            onClose={() => setSwiModal(false)}
            open={swiModalOpen}
            tenantId={permissions.getTenantId()}
            superTenantId={permissions.isSuperAdmin() ? null : permissions.getSuperTenantId()}
            onConfirm={handleTenantSwitch}
          />
        )}
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  globalView: state.settings.globalView,
});

const mapDispatchToProps = (dispatch) => ({
  resetUserSettings: () => {
    dispatch(setSettings({}));
  },
  updateGlobalView: (globalView) => {
    dispatch(updateSettings({ globalView }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAppBar);
