import React from 'react';
import { useInput } from 'react-admin';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-github';

const FormHtmlEditor = (props) => {
  const {
    input: { onChange, value },
  } = useInput(props);

  return (
    <AceEditor
      width="100%"
      mode="html"
      theme="github"
      onChange={onChange}
      value={value}
    />
  );
};

export default FormHtmlEditor;
