// in src/App.js
import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import MyLayout from './layout/Layout';
import theme from './common/theme';
import DriverList from './drivers/DriverList';
import { ReactComponent as DriverIcon } from './assets/icons/sidebar/driver.svg';
import { ReactComponent as DriverActiveIcon } from './assets/icons/sidebar/driver_active.svg';
import { ReactComponent as AdminIcon } from './assets/icons/sidebar/admin.svg';
import { ReactComponent as AdminActiveIcon } from './assets/icons/sidebar/admin_active.svg';
import { ReactComponent as PhotoControlIcon } from './assets/icons/sidebar/photo_control.svg';
import {
  ReactComponent as PhotoControlActiveIcon,
} from './assets/icons/sidebar/photo_control_active.svg';
import DriverCreate from './drivers/DriverCreate';
import i18nProvider from './common/i18nProvider';
import resourceDataProvider from './providers/resourceDataProvider';
import DriverShow from './drivers/DriverShow';
import authProvider from './providers/authProvider';
import MyLoginPage from './layout/LoginPage';
import authReducer from './redux/authReducer';
import customRoutes from './customRoutes';
import DriverEdit from './drivers/DriverEdit';
import UserList from './users/UserList';
import UserShow from './users/UserShow';
import UserCreate from './users/UserCreate';
import UserEdit from './users/UserEdit';
import statsSaga from './redux/statsSaga';
import statsReducer from './redux/statsReducer';
import BusinessUnitList from './businessUnits/BusinessUnitList';
import BusinessUnitCreate from './businessUnits/BusinessUnitCreate';
import BusinessUnitEdit from './businessUnits/BusinessUnitEdit';
import TenantList from './tenant/TenantList';
import TenantEdit from './tenant/TenantEdit';
import TenantCreate from './tenant/TenantCreate';
import LogsList from './admin/LogsList';
import TemplateList from './templates/TemplateList';
import TemplateEdit from './templates/TemplateEdit';
import SuperTenantList from './superTenant/SuperTenantList';
import SuperTenantEdit from './superTenant/SuperTenantEdit';
import SuperTenantCreate from './superTenant/SuperTenantCreate';
import InvoiceList from './invoices/InvoiceList';
import SupertenantInvoiceList from './invoices/SuperTenantInvoiceList';
import StatPerformanceList from './admin/StatPerformanceList';
import PhotoControlList from './photoControls/PhotoControlList';
import PhotoControlShow from './photoControls/PhotoControlShow';
import settingsSaga from './redux/settingsSaga';
import settingsReducer from './redux/settingsReducer';
import GroupList from './group/GroupList';
import GroupEdit from './group/GroupEdit';
import GroupCreate from './group/GroupCreate';

const App = () => (
  <Admin
    title=""
    theme={theme}
    dataProvider={resourceDataProvider}
    i18nProvider={i18nProvider}
    authProvider={authProvider}
    layout={MyLayout}
    loginPage={MyLoginPage}
    customReducers={{ auth: authReducer, stats: statsReducer, settings: settingsReducer }}
    customSagas={[statsSaga, settingsSaga]}
    customRoutes={customRoutes}
  >
    <Resource
      name="drivers"
      options={{
        label: 'pages.driverList.title',
        activeIcon: DriverActiveIcon,
      }}
      icon={DriverIcon}
      list={DriverList}
      create={DriverCreate}
      show={DriverShow}
      edit={DriverEdit}
    />
    <Resource
      name="businessUnits"
      options={{
        noMenu: true,
        label: 'businessUnits.plural',
      }}
      list={BusinessUnitList}
      create={BusinessUnitCreate}
      edit={BusinessUnitEdit}
    />
    <Resource
      name="users"
      options={{
        noMenu: true,
        label: 'pages.admin.title',
        activeIcon: AdminActiveIcon,
      }}
      icon={AdminIcon}
      list={UserList}
      create={UserCreate}
      show={UserShow}
      edit={UserEdit}
    />
    <Resource
      name="tenants"
      options={{
        noMenu: true,
        label: 'tenants.plural',
      }}
      list={TenantList}
      edit={TenantEdit}
      create={TenantCreate}
    />
    <Resource
      name="super-tenants"
      options={{
        noMenu: true,
        label: 'superTenants.plural',
      }}
      list={SuperTenantList}
      edit={SuperTenantEdit}
      create={SuperTenantCreate}
    />
    <Resource
      name="photo-controls"
      options={{
        label: 'photoControls.plural',
        activeIcon: PhotoControlActiveIcon,
        showFunc: (permissions) => permissions
          && (permissions.canEdit()
            || permissions.hasPhotoRollout() || permissions.hasPhotoControl()),
      }}
      icon={PhotoControlIcon}
      list={PhotoControlList}
      show={PhotoControlShow}
    />
    <Resource
      name="stat_logs"
      options={{
        noMenu: true,
        label: 'logs.label',
      }}
      list={LogsList}
    />
    <Resource
      name="stat_perf"
      options={{
        noMenu: true,
        label: 'statsPerf.label',
      }}
      list={StatPerformanceList}
    />
    <Resource
      name="templates"
      list={TemplateList}
      edit={TemplateEdit}
      options={{
        noMenu: true,
        label: 'templates.label',
      }}
    />
    <Resource
      name="invoices"
      list={InvoiceList}
      options={{ noMenu: true, label: 'invoices.label' }}
    />
    <Resource
      name="supertenant-invoices"
      list={SupertenantInvoiceList}
      options={{ noMenu: true, label: 'superTenantInvoices.label' }}
    />
    <Resource
      name="groups"
      list={GroupList}
      edit={GroupEdit}
      create={GroupCreate}
      options={{ noMenu: true, label: 'groups.plural' }}
    />
  </Admin>
);

export default App;
