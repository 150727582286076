import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import React from 'react';

/**
 * Custom Type definitions for better intellisense
 * @typedef {import('@material-ui/core').InputProps} InputProps
 * @typedef {import('@material-ui/core').TextFieldProps} TextFieldProps
 * @typedef {import('react-hook-form').RegisterOptions} RegisterOptions
 */

/**
 * A custom form text field component integrated with react-hook-form and Material-UI.
 *
 * @param {string} label - The key for internationalization (i18n) for the label text.
 * @param {string} name - The form name that will be used for form methods.
 * @param {string} [id] - The base ID that will be used for the component.
 * @param {string} [helperText] - The key for internationalization (i18n) for the helper text.
 * @param {string} [defaultValue] - The default value of the formField.
 * @param {RegisterOptions} [options] - The react-hook-form register options for validation and
 *     other settings.
 * @param {TextFieldProps} textFieldRest - Additional input properties from Material-UI's
 *     TextFieldProps.
 * @returns {JSX.Element} A JSX element representing a form text field with validation and helper
 *     text.
 */
// eslint-disable-next-line import/prefer-default-export
export function FormTextField({
  label,
  id,
  name,
  helperText,
  defaultValue = '',
  options = {},
  ...textFieldRest
}) {
  const translate = useTranslate();
  const {
    register,
    formState,
  } = useFormContext();
  const errorMessage = formState.errors[name];
  return (
    <TextField
      fullWidth
      required={Boolean(options.required)}
      variant="outlined"
      id={id}
      label={translate(label)}
      error={Boolean(errorMessage)}
      defaultValue={defaultValue}
      style={{ marginBottom: '1rem' }}
      helperText={errorMessage?.message ?? translate(helperText)}
      {...textFieldRest}
      {...register(name, options)}
    />
  );
}
