/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useMenuStyles = makeStyles(() => ({
  paper: {
    minWidth: (props) => props.width,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  list: {
    minWidth: (props) => props.width,
    padding: 'unset',
  },
}));

const useStyles = makeStyles((theme) => ({
  listItem: {
    height: 52,
    borderTop: `1px solid ${theme.palette.gray.light}`,
  },
  listIcon: {
    minWidth: 20,
    marginRight: 23,
  },
  itemText: {
    fontWeight: 600,
  },
}));

const PopoverMenu = ({
  anchorEl, width, menuOptions, onClose,
}) => {
  const menuStyles = useMenuStyles({ width });
  const classes = useStyles();
  const renderMenuOptions = () => menuOptions
    .filter((o) => !o.hidden).map((option) => (
      <MenuItem
        disabled={option.disabled}
        key={option.label}
        className={classes.listItem}
        onClick={option.onClick}
      >
        <ListItemIcon className={classes.listIcon}>
          {option.icon}
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.itemText }} primary={option.label} />
      </MenuItem>

    ));

  // rebuild: anchor set on click https://material-ui.com/components/menus/#CustomizedMenus.js
  return (
    <Menu
      classes={menuStyles}
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      style={{ width }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {renderMenuOptions()}
    </Menu>
  );
};

PopoverMenu.propTypes = {
  anchorEl: PropTypes.object,
  width: PropTypes.number,
  menuOptions: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.object,
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
  })),
  onClose: PropTypes.func,
};

PopoverMenu.defaultProps = {
  anchorEl: null,
  width: 150,
  menuOptions: [],
  onClose: () => {
  },
};

export default PopoverMenu;
