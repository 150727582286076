import React from 'react';
import { useTranslate } from 'react-admin';
import useTheme from '@material-ui/core/styles/useTheme';
import {
  Cell, Legend, Pie, PieChart,
} from 'recharts';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  legendContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 5px',
    margin: 5,
    borderLeft: '3px solid green',
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
  },
  value: {
    fontSize: 14,
    color: 'black',
  },
}));

const DriverByStatusChart = ({ monitoring }) => {
  const translate = useTranslate();
  const theme = useTheme();
  const classes = useStyles();

  const data = [
    {
      label: translate('drivers.states.fail'),
      value: monitoring.driversCountByStatus0,
      color: theme.palette.red.main,
    },
    {
      label: translate('drivers.states.pending'),
      value: monitoring.driversCountByStatus1,
      color: theme.palette.yellow.main,
      secData: [
        {
          label: translate('pages.monitoring.driversByStatus.overdue'),
          value: monitoring.driversCountOverdueFourWeeks,
        },
        {
          label: translate('pages.monitoring.driversByStatus.pending'),
          value: monitoring.driversCountPendingInit,
        },
      ],
    },
    {
      label: translate('drivers.states.success'),
      value: monitoring.driversCountByStatus2,
      color: theme.palette.green.main,
    },
  ];

  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, value,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius - 10) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {value > 0 ? value : null}
      </text>
    );
  };

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <div>
        {
          payload.map((entry, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={classes.legendContainer}
              style={{ borderColor: entry.payload.color }}
            >
              <span className={classes.label}>{entry.payload.label}</span>
              <span className={classes.value}>{entry.payload.value}</span>
            </div>
          ))
        }
      </div>
    );
  };

  return (
    <PieChart width={430} height={300}>
      <Pie
        activeIndex={1}
        data={data}
        cx={140}
        dataKey="value"
        innerRadius={70}
        outerRadius={100}
        fill="#8884d8"
        animationBegin={0}
        animationDuration={0}
        label={renderCustomizedLabel}
        labelLine={false}
        paddingAngle={1}
        startAngle={200}
        endAngle={560}
      >
        {
          data.map((val) => (
            <Cell key={val.color} fill={val.color} />
          ))
        }
      </Pie>
      <Legend
        wrapperStyle={{ top: 100 }}
        content={renderLegend}
        align="right"
        layout="vertical"
        height={36}
      />
    </PieChart>
  );
};

export default DriverByStatusChart;
