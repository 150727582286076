import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDataProvider, useTranslate } from 'react-admin';

const GroupSelect = ({ selected, onChange, superTenantId }) => {
  const [groups, setGroups] = useState([]);
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const handleChange = (e, value) => {
    onChange(value);
  };

  const fetchGroups = (e, search) => dataProvider.getList('groups', {
    pagination: { page: 1, perPage: 50 },
    filter: superTenantId ? { fk_superTenantId: superTenantId, q: search } : { q: search },
    sort: {},
  })
    .then((res) => {
      if (res && res.data) {
        setGroups(res.data);
        return res.data;
      }
      return [];
    });

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line
  }, [superTenantId]);

  return (
    <Autocomplete
      value={selected}
      onInputChange={fetchGroups}
      onChange={handleChange}
      getOptionLabel={(option) => option && option.name}
      options={groups}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={translate('groups.label')}
          variant="outlined"
        />
      )}
    />
  );
};

export default GroupSelect;
