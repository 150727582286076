import { baseApi } from '../common/envUtils';
import httpClient from './httpClient';

const customProvider = {
  customAction: (resource, params) => {
    const { id, action, ...reqBody } = params;

    const url = `${baseApi}/${resource}/${id}/${action}`;
    return httpClient(url, {
      method: 'POST',
      body: reqBody ? JSON.stringify(reqBody) : null,
    }).then(({ json }) => ({ data: json }));
  },
  customBulk: (resource, params) => {
    const { action, ...reqBody } = params;

    let url = `${baseApi}/${resource}/batch`;
    if (action) {
      url += `/${action}`;
    }
    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(reqBody),
    }).then(({ json }) => ({ data: json }));
  },
  get: (resource) => {
    const url = `${baseApi}/${resource}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },
};

export default customProvider;
