import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { usePostHog } from 'posthog-js/react';
import { Typography } from '@material-ui/core';
import PasswordResetPage from './auth/PasswordResetPage';
import VerifyPage from './auth/VerifyPage';
import ActivationPage from './auth/ActivationPage';
import DashboardPage from './dashboard/DashboardPage';
import FeaturesPage from './admin/FeaturesPage';
import OrderLabelPage from './admin/OrderLabelPage';
import MonitoringPage from './monitoring/MonitoringPage';
import SettingsPage from './settings/SettingsPage';
import SupportPage from './support/SupportPage';
import ScheduleEventPage from './scheduleEvent/ScheduleEventPage';
import LabelCodesPage from './admin/LabelCodesPage';
import DriverUploadPage from './drivers/DriverUploadPage';
import DriverReportList from './drivers/DriverReportList';

/* eslint-disable */
export function DevDemo() {
  const posthog = usePostHog()
  useEffect(() => {

    if (posthog) {
      posthog.capture('test')
    }
  }, [posthog])

  if (posthog.isFeatureEnabled('test-mc') ) {
    return <Typography>POSTHOG</Typography>
  }
  return (
    <Box>

      <Button onClick={() => {
        methodDoesNotExist();
      }}>
        Sentry Error
      </Button> <Button onClick={() => {
        posthog?.capture('posthog_test_success')

    }}>
      Posthog Event
    </Button>
    </Box>
  );
}

export default [
  <Route key="dev-demo" exact path="/dev/demo" component={DevDemo} noLayout />,
  <Route key="auth-reset" exact path="/auth/reset" component={PasswordResetPage} noLayout />,
  <Route key="auth-verify" exact path="/auth/verify" component={VerifyPage} noLayout />,
  <Route key="auth-activation" exact path="/activation" component={ActivationPage} noLayout />,
  <Route key="dashboard" exact path="/dashboard" component={DashboardPage} />,
  <Route key="monitoring" exact path="/monitoring" component={MonitoringPage} />,
  <Route key="admin-features" exact path="/admin/features" component={FeaturesPage} />,
  <Route key="admin-order-label" exact path="/admin/order-label" component={OrderLabelPage} />,
  <Route key="settings" exact path="/settings" component={SettingsPage} />,
  <Route key="support" exact path="/support" component={SupportPage} />,
  <Route key="schedule-event" exact path="/schedule-event" component={ScheduleEventPage} noLayout />,
  <Route key="label-codes" exact path="/label-codes" component={LabelCodesPage} />,
  <Route key="driver-upload" exact path="/drivers-upload" component={DriverUploadPage} />,
  <Route key="driver-report" exact path="/drivers-report" component={DriverReportList} />,
  <Redirect key="redirect" exact from="/" to="/dashboard" />,
];
