import React from 'react';
import { useInput, useTranslate } from 'react-admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  hint: {
    fontSize: 11,
    color: theme.palette.gray.main,
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
}));

const FormCheckbox = (props) => {
  const {
    input: { name, onChange, value },
  } = useInput(props);
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <FormControlLabel
      style={{ width: props.fullWidth ? '100%' : null, marginBottom: 10 }}
      className={props.className}
      control={(
        <Checkbox
          checked={value}
          onChange={onChange}
          name={name}
          disabled={props.disabled}
          color="primary"
        />
      )}
      label={
        (
          <div className={classes.labelContainer}>
            <Typography variant="body1">{translate(props.label)}</Typography>
            {Boolean(props.hint) && (
              <span className={classes.hint}>
                {translate(props.hint)}
              </span>
            )}
          </div>
        )
      }
    />
  );
};

export default FormCheckbox;
