import React, { useRef, useState } from 'react';
import {
  Confirm,
  Edit,
  FormWithRedirect,
  SaveButton,
  useDataProvider,
  useNotify, useRefresh,
  useTranslate,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import TenantForm from './TenantForm';
import { resizeImage } from '../common/utils';
import customProvider from '../providers/customProvider';

const useStyles = makeStyles(() => ({
  kpiLabel: {
    fontSize: 16,
    fontWeight: 600,
  },
  kpiValue: {
    fontSize: 18,
    fontWeight: 500,
  },
  imageItem: {
    maxWidth: 230,
    maxHeight: 64,
    objectFit: 'scale-down',
  },
}));

const TenantEditToolbar = (props) => {
  const translate = useTranslate();
  const {
    invalid, pristine, saving, handleSubmitWithRedirect, record, resource,
  } = props;

  const [confirm, setConfirm] = useState(false);
  const [key, setKey] = useState('');

  const generateApiKey = async () => {
    const { data } = await customProvider.customAction(resource, {
      id: record.id,
      action: 'generate-api-key',
    });
    setKey(data.token);
    setConfirm(false);
  };

  return (
    <div style={{ backgroundColor: 'inherit' }}>
      {key && (
        <Card style={{ backgroundColor: 'gray' }}>
          <Box m={5}>
            <Typography variant="subtitle1">{translate('tenants.sections.key')}</Typography>
            <Typography style={{ overflowWrap: 'break-word' }} variant="body1">{key}</Typography>
          </Box>
        </Card>
      )}
      <SaveButton
        variant="contained"
        saving={saving}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        label="tenants.actions.save"
        redirect={false}
        submitOnEnter={false}
        disabled={invalid || pristine}
      />
      {record.id && record.external && (
        <Button
          onClick={() => setConfirm(true)}
        >
          {translate('tenants.actions.external')}
        </Button>
      )}
      <Confirm
        isOpen={confirm}
        title="tenants.actions.external"
        content="tenants.actions.externalHint"
        onConfirm={generateApiKey}
        onClose={() => setConfirm(false)}
      />
    </div>
  );
};

const TenantEdit = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const inputEl = useRef(null);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleFileChange = async () => {
    if (inputEl && inputEl.current && inputEl.current.files.length > 0) {
      try {
        const logo = await resizeImage(inputEl.current.files[0]);
        await dataProvider.update('tenants', {
          id: props.id, data: { logo },
        });
        notify('common.success');
        refresh();
      } catch (e) {
        notify('common.fail');
      }
    }
  };

  return (
    <div>
      <TitleBreadcrumbs
        path={[
          { label: translate('pages.admin.title'), to: 'tenants' },
        ]}
        title={translate('tenants.actions.edit')}
      />
      <Edit {...props}>

        <FormWithRedirect
          render={(formProps) => (
            <Box width="100%" display="flex" flexDirection="row" flexWrap="wrap">
              <TenantForm formProps={formProps} toolbar={<TenantEditToolbar {...formProps} />} />

              <Box width="20%" padding={3} display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="subtitle1"
                    style={{ marginBottom: 8 }}
                  >
                    {translate('tenants.sections.counter')}
                  </Typography>
                  <span className={classes.kpiLabel}>{translate('tenants.fields.fskCount')}</span>
                  <span className={classes.kpiValue}>{formProps.record.fskCount}</span>
                  <span className={classes.kpiLabel}>{translate('tenants.fields.emailCount')}</span>
                  <span className={classes.kpiValue}>{formProps.record.emailCount}</span>
                  <span className={classes.kpiLabel}>{translate('tenants.fields.smsCount')}</span>
                  <span className={classes.kpiValue}>{formProps.record.smsCount}</span>
                </Box>

                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="subtitle1"
                    style={{ marginBottom: 8 }}
                  >
                    {translate('tenants.sections.logo')}
                  </Typography>

                  <Box padding={3}>
                    {formProps.record.logo && (
                      <img
                        src={formProps.record.logo}
                        alt="logo"
                        className={classes.imageItem}
                      />
                    )}
                  </Box>

                  <Button
                    style={{ marginRight: 20 }}
                    variant="contained"
                    component="label"
                    color="primary"
                  >
                    UPLOAD
                    <input
                      ref={inputEl}
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      hidden
                    />
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        />
      </Edit>
    </div>

  );
};

export default TenantEdit;
