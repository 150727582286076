import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useAuthProvider, usePermissions } from 'react-admin';
import PermissionUtils from '../common/permissionUtils';

/**
 * Context to provide user and permissions information.
 *
 * @type {React.Context<{ user: Object | null, permissions: Object | null }>}
 */
export const UserPermissionsContext = createContext({
  user: null,
  permissions: null,
});

/**
 * Custom hook to access the UserPermissionsContext.
 *
 * @function useUserPermissionsContext
 * @returns {Object} An object containing the user and permissions.
 * @property {Object | null} user - The current user information.
 * @property {Object | null} permissions - The current user permissions.
 *
 * @example
 * const { user, permissions } = useUserPermissionsContext();
 */
export function useUserPermissionsContext() {
  return useContext(UserPermissionsContext);
}

/**
 * Provider component to manage user permissions based on the selected tenant.
 *
 * @function UserPermissionsProvider
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render.
 * @returns {React.ReactElement} The provider component with user and permissions context.
 *
 * @example
 * <UserPermissionsProvider>
 *   <YourComponent />
 * </UserPermissionsProvider>
 */
export function UserPermissionsProvider({ children }) {
  const methods = useFormContext();
  const authProvider = useAuthProvider();

  const tenantId = methods.watch('tenant');
  const [tenantIdSet, setTenantIdSet] = useState(tenantId);
  const [user, setUser] = useState(tenantId);

  useEffect(() => {
    authProvider.switchTenant(tenantId).then(() => {
      const userDataString = localStorage.getItem('user');
      try {
        const userData = JSON.parse(userDataString);
        setUser(userData);
        setTenantIdSet(userData.tenantId);
      } catch (error) {
        console.log(error);
      }
    });
  }, [tenantId, authProvider]);

  const permissionsBase = usePermissions(useMemo(() => ({ tenantIdSet }), [tenantIdSet]));
  const permissions = useMemo(() => new PermissionUtils(permissionsBase), [permissionsBase]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <UserPermissionsContext.Provider value={{ user, permissions }}>
      {children}
    </UserPermissionsContext.Provider>
  );
}
