import React from 'react';
import {
  Notification, useLogin, useNotify, useTranslate,
} from 'react-admin';
import { ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { connect } from 'react-redux';
import Icon from '../assets/img/edding-logo.svg';
import {
  setPasswordAction,
  setTwoFactorAuthCode,
  setTwoFaEnabled,
  setUsernameAction,
} from '../redux/authReducer';
import PasswordTextField from '../components/PasswordTextField';
import { fetchSettings } from '../redux/settingsReducer';
import package_ from '../../package.json';

const useStyles = makeStyles(() => ({
  logoImage: {
    objectFit: 'scale-down',
    width: 445,
    height: 128,
  },
  titleContainer: {
    paddingBottom: 38,
    borderBottom: (props) => `1px solid ${props.secondaryColor}`,
  },
}));

const MyLoginPage = ({
  theme,
  username,
  password,
  onUsernameChange,
  onPasswordChange,
  history,
  twoFactorAuthCode,
  onTwoFactorAuthCodeChange,
  twoFaEnabled,
  onTwoFaEnabledChange,
  fetchUserSettings,
}) => {
  const classes = useStyles({ secondaryColor: theme.palette.secondary.main });
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();

  const submit = (e) => {
    e.preventDefault();
    login({ username, password, twoFactorAuthCode }).then((data) => {
      onPasswordChange('');
      onUsernameChange('');
      if (data.require2FA) {
        notify(translate('ra.auth.require_2fa'));
        history.push('/settings');
      }
      fetchUserSettings();
      onTwoFaEnabledChange(false);
    }).catch((err) => {
      if (err.body && err.body.twoFactorEnabled) {
        onTwoFaEnabledChange(true);
        if (twoFactorAuthCode) {
          notify(translate('ra.auth.invalid_2fa'));
        }
      } else {
        onTwoFaEnabledChange(false);
        notify(translate('ra.auth.sign_in_error'));
      }
    });
  };

  const handlePasswordResetClick = () => {
    history.push('/auth/reset');
  };

  return (
    <ThemeProvider theme={theme}>
      <Container fixed>
        <Box
          height="90vh"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            height="80%"
            maxHeight={700}
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
          >

            <img className={classes.logoImage} src={Icon} alt="company_logo" />

            <Box className={classes.titleContainer}>
              <Typography variant="h1">{translate('pages.login.title')}</Typography>
              <Typography variant="h2">{translate('pages.login.subtitle')}</Typography>
            </Box>
            <form onSubmit={submit}>
              <Box
                maxWidth={520}
                width="50vw"
                height={350}
                display="flex"
                flexDirection="column"
                flexWrap="wrap"
                justifyContent="start"
              >
                <Box my={1}>
                  <Typography variant="subtitle1">Login</Typography>
                </Box>
                <Box my={2}>
                  <TextField
                    label={translate('ra.auth.username')}
                    name="username"
                    type="username"
                    value={username}
                    onChange={(e) => onUsernameChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Box>
                <Box mb={2}>
                  <PasswordTextField
                    label={translate('ra.auth.password')}
                    name="password"
                    value={password}
                    onChange={(e) => onPasswordChange(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Box>

                {twoFaEnabled && (
                  <Box mb={2}>
                    <TextField
                      label={translate('pages.settings.twoFa.twoFactorAuthCode')}
                      name="twoFactorAuthCode"
                      value={twoFactorAuthCode}
                      onChange={(e) => onTwoFactorAuthCodeChange(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                )}

                <Box display="flex" flexDirection="row">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ width: 140 }}
                    disabled={!username || !password}
                  >
                    {translate('ra.auth.sign_in')}
                  </Button>
                  <Button
                    color="primary"
                    style={{ width: 180 }}
                    onClick={handlePasswordResetClick}
                  >
                    {translate('pages.login.passwordForget')}
                  </Button>
                </Box>

              </Box>

            </form>
          </Box>
        </Box>
        {`v${package_.version}`}
      </Container>

      <Notification />
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  username: state.auth.username,
  password: state.auth.password,
  twoFactorAuthCode: state.auth.twoFactorAuthCode,
  twoFaEnabled: state.auth.twoFaEnabled,
});

const mapDispatchToProps = (dispatch) => ({
  onUsernameChange: (username) => {
    dispatch(setUsernameAction(username));
  },
  onPasswordChange: (password) => {
    dispatch(setPasswordAction(password));
  },
  onTwoFactorAuthCodeChange: (twoFaCode) => {
    dispatch(setTwoFactorAuthCode(twoFaCode));
  },
  onTwoFaEnabledChange: (twoFaEnabled) => {
    dispatch(setTwoFaEnabled(twoFaEnabled));
  },
  fetchUserSettings: () => {
    dispatch(fetchSettings());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyLoginPage);
