import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 4,
    border: `solid 1px ${theme.palette.primary.light}`,
    backgroundColor: theme.palette.white.main,
  },
  statusIndicator: {
    backgroundColor: ({ color }) => (theme.palette[color] ? theme.palette[color].main : color),
    height: 'inherit',
    width: 6,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    alignSelf: 'stretch',
  },
}));

const IndicatorCard = ({ color, classes, children }) => {
  const defaultClasses = useStyles({ color });
  return (
    <Box
      className={classNames(defaultClasses.card, classes && classes.container)}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <div className={classNames(defaultClasses.statusIndicator, classes && classes.indicator)} />
      {children}
    </Box>

  );
};

export default IndicatorCard;
