import React from 'react';
import {
  Document, Page, StyleSheet, Text, View,
} from '@react-pdf/renderer';
import { parseISO } from 'date-fns';
import { formatIsoDate, formatIsoTime } from '../common/dateUtils';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    padding: '17px 10px',
    fontSize: 15,
    color: '#00368e',
  },
  card: {
    width: 340,
    height: '95vh',
    padding: '12px 12px 16px 12px',
    borderRadius: 4,
    borderColor: '#e8eaf0',
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: '#ffffff',
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  sectionTitle: {
    color: '#00368e',
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 10,
  },
  bold: {
    color: 'black',
    fontSize: 12,
    fontWeight: 600,
  },
  label: {
    color: '#9ba0b1',
    fontSize: 12,
  },
  value: {
    fontSize: 12,
  },

  licenseInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 360,
  },

  infoItem: {
    padding: '6px 6px 6px 0',
    width: 150,
  },

  indCard: {
    width: 160,
    height: 34,
    borderRadius: 4,
    borderColor: '#e8eaf0',
    borderWidth: 2,
    borderStyle: 'solid',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  indStatus: {
    height: 32,
    width: 6,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    marginRight: 5,
    justifySelf: 'flex-start',
    alignSelf: 'stretch',
  },

  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '95vh',
  },
});

const getStatusColor = (status) => {
  switch (status) {
    case -1:
    case 6:
      return '#00368e';
    case 1:
    case 4:
    case 3:
    case 5:
      return '#ffd740';
    case 2:
      return '#81c784';
    case 0:
      return '#e64a19';
    default:
      return '#9ba0b1';
  }
};

const IndicatorCard = ({ status, text }) => (
  <View style={styles.indCard}>
    <View style={{ ...styles.indStatus, backgroundColor: getStatusColor(status) }} />
    <Text style={styles.bold}>{text}</Text>
  </View>
);

const DriverFilePdf = ({ driver, includeUvv, translate }) => {
  const getFsStatusText = (status) => {
    switch (status) {
      case 1:
      case 4:
      case 3:
      case 5:
        return translate('drivers.events.fsPending');
      case 2:
        return translate('drivers.events.fsDone');
      case 0:
        return translate('drivers.events.fsFail');
      case 6:
      default:
        return translate('drivers.events.newDriver');
    }
  };

  const getEventTitle = (eventType) => {
    if (eventType > 100) {
      return translate(`templates.types.${eventType}`);
    }

    switch (eventType) {
      case 1:
        return translate('drivers.events.newDriver');
      case 2:
        return translate('drivers.events.fsManual');
      case 3:
        return translate('drivers.events.fsPending');
      case 4:
        return translate('drivers.events.fsRequested');
      case 5:
        return translate('drivers.events.fsDone');
      case 6:
        return translate('drivers.events.fsOverdue');
      case 7:
        return translate('drivers.events.fsAdhoc');
      case 8:
        return translate('drivers.events.fsFail');
      case 9:
        return translate('drivers.events.fsFailFirst');
      case 10:
        return translate('drivers.events.initRequested');
      case 11:
        return translate('drivers.events.initFailed');
      case 12:
        return translate('drivers.events.initSuccess');
      case 13:
        return translate('drivers.events.uvvPending');
      case 14:
        return translate('drivers.events.uvvRequested');
      case 15:
        return translate('drivers.events.uvvDone');
      case 16:
        return translate('drivers.events.uvvOverdue');
      case 17:
        return translate('drivers.events.uvvFail');
      case 18:
        return translate('drivers.events.fsCancel');
      case 19:
        return translate('drivers.events.eventScheduled');
      case 20:
        return translate('drivers.events.fsManualSa');
      default:
        return translate('drivers.events.next');
    }
  };

  const displayEvents = driver.Events
    ? [...driver.Events].filter((e) => !e.text || e.text !== 'retry') : [];
  displayEvents.sort((a, b) => parseISO(b.createdAt) - parseISO(a.createdAt));

  const firstPageEvents = displayEvents.slice(0, 24);
  const secondPageEvents = displayEvents.slice(24);

  const renderEvents = (events) => (
    <View style={styles.eventsContainer}>
      {events.map((event) => (
        <View
          key={event.id}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 5,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              marginRight: 10,
            }}
          >
            <Text style={styles.value}>{formatIsoDate(event.createdAt)}</Text>
            <Text style={styles.label}>{formatIsoTime(event.createdAt)}</Text>
          </View>

          <IndicatorCard
            status={event.status}
            text={getEventTitle(event.eventType)}
          />

        </View>
      ))}
    </View>
  );

  return (
    <Document>
      <Page orientation="landscape" size="A4">
        <View style={styles.container}>
          <View style={styles.card}>
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>{translate('drivers.sections.contactInfo')}</Text>
              <Text style={styles.value}>{`${driver.firstName}  ${driver.surName}`}</Text>
              <Text style={styles.value}>{driver.email}</Text>
              {(driver.driverTypeEnum !== null) && (
                <Text style={styles.value}>
                  {translate(`drivers.driverTypes.${driver.driverTypeEnum}`)}
                </Text>

              )}
            </View>

            <View style={styles.section}>
              <Text style={styles.label}>{translate('pages.driverShow.statusFS')}</Text>

              <IndicatorCard
                status={driver.statusFS}
                text={getFsStatusText(driver.statusFS)}
              />
            </View>

            {includeUvv && (
              <View style={styles.section}>
                <Text style={styles.label}>{translate('pages.driverShow.statusUVV')}</Text>

                <IndicatorCard
                  status={driver.statusUVV}
                  text={getFsStatusText(driver.statusUVV)}
                />
              </View>
            )}

            <View style={styles.section}>
              <Text
                style={styles.sectionTitle}
              >
                {translate('drivers.sections.licenseInfo')}
              </Text>

              <View style={styles.licenseInfoContainer}>
                {Boolean(driver.licenseIntervalDays) && (
                  <View style={styles.infoItem}>

                    <Text style={styles.label}>
                      {translate('drivers.fields.licenseInterval')}
                    </Text>

                    <Text style={styles.value}>
                      {Math.round(driver.licenseIntervalDays / 30)}
                      {' '}
                      {translate('drivers.fields.licenseIntervalPostfix')}
                    </Text>
                  </View>
                )}

                {Boolean(driver.licenseLabelCode) && (
                  <View style={styles.infoItem}>

                    <Text style={styles.label}>
                      {translate('drivers.fields.licenseLabelCode')}
                    </Text>

                    <Text style={styles.value}>
                      {driver.licenseLabelCode}
                    </Text>
                  </View>
                )}

                {driver.licenseType !== null && (
                  <View style={styles.infoItem}>

                    <Text style={styles.label}>
                      {translate('drivers.fields.licenseType')}
                    </Text>

                    <Text style={styles.value}>
                      {translate(`drivers.licenseType.${driver.licenseType}`)}
                    </Text>
                  </View>
                )}

                {Boolean(driver.licenseExpiryDate) && (
                  <View style={styles.infoItem}>

                    <Text style={styles.label}>
                      {translate('drivers.fields.licenseExpiryDate')}
                    </Text>

                    <Text style={styles.value}>
                      {formatIsoDate(driver.licenseExpiryDate)}
                    </Text>
                  </View>
                )}

                {Boolean(driver.statusFSVisualControlText) && (
                  <View style={styles.infoItem}>

                    <Text style={styles.label}>
                      {translate('drivers.fields.statusFSVisualControlText')}
                    </Text>

                    <Text style={styles.value}>
                      {driver.statusFSVisualControlText}
                    </Text>
                  </View>
                )}

                {Boolean(driver.licenseComment1) && (
                  <View style={styles.infoItem}>

                    <Text style={styles.label}>
                      {translate('drivers.fields.licenseComment1')}
                    </Text>

                    <Text style={styles.value}>
                      {driver.licenseComment1}
                    </Text>
                  </View>
                )}

              </View>

              {Boolean(driver.User) && (
                <View style={styles.section}>
                  <Text style={styles.sectionTitle}>
                    {translate('drivers.sections.responsible')}
                  </Text>

                  <Text style={styles.value}>
                    {`${driver.User.firstName} ${driver.User.surName}`}
                  </Text>
                </View>
              )}

            </View>
          </View>

          <View style={{ ...styles.card, width: 550 }}>
            {renderEvents(firstPageEvents)}
          </View>
        </View>
      </Page>
      {secondPageEvents.length > 0 && (
        <Page orientation="landscape" size="A4">
          <View style={styles.card}>
            {renderEvents(secondPageEvents)}
          </View>
        </Page>
      )}

    </Document>
  );
};

export default DriverFilePdf;
