import React, { useEffect, useState } from 'react';
import {
  required,
  TextInput,
  useAuthProvider,
  useDataProvider,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import FormSubtitle from '../components/form/FormSubtitle';
import { validatePasswordPolicy } from '../common/validateUtils';
import PermissionUtils from '../common/permissionUtils';
import TwoFAFlow from './TwoFAFlow';
import { baseApi } from '../common/envUtils';
import httpClient from '../providers/httpClient';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '26px 32px',
    maxWidth: 450,
    margin: '12px 0',
    flex: 1,
  },
}));

const SettingsPage = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const authProvider = useAuthProvider();
  const dataProvider = useDataProvider();
  const permissions = new PermissionUtils(usePermissions());
  const [initValue, setInitValue] = useState({});
  const [user, setUser] = useState(null);

  const fetchDriver = () => dataProvider.getOne(
    'users',
    { id: permissions.getId() },
  ).then((res) => {
    if (res && res.data) {
      setUser(res.data);
    }
  });

  useEffect(() => {
    fetchDriver();
    // eslint-disable-next-line
  }, [permissions]);

  const submit = (values) => {
    const { oldPassword, newPassword } = values;
    if (oldPassword && newPassword) {
      authProvider.password({ oldPassword, newPassword }).then(() => {
        notify('common.success');
        setInitValue({});
      });
    }
  };

  const validateSamePw = (values) => {
    const errors = {};
    if (values.newPassword && values.newPasswordConfirm
      && values.newPassword !== values.newPasswordConfirm
    ) {
      errors.newPasswordConfirm = translate('pages.settings.changePassword.notIdenticalError');
    }
    return errors;
  };

  const handleTwoFacDisable = async () => {
    await dataProvider.update('users', {
      id: permissions.getId(), data: { twoFactorEnabled: false },
    });
    await fetchDriver();
  };

  const handleTwoFacEnable = async (twoFactorAuthCode) => {
    const url = `${baseApi}/auth/2fa/turn-on`;
    try {
      await httpClient(url, {
        method: 'POST',
        body: JSON.stringify({ twoFactorAuthCode }),
      });
      notify('common.success');
    } catch (err) {
      notify('common.fail');
    }
  };

  return (
    <div>
      <TitleBreadcrumbs title={translate('pages.settings.title')} />
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Paper className={classes.card}>
          <Form
            validate={validateSamePw}
            initialValues={initValue}
            onSubmit={submit}
          >
            {({
              handleSubmit, pristine, error, invalid,
            }) => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <FormSubtitle>{translate('pages.settings.changePassword.title')}</FormSubtitle>
                <TextInput
                  label={translate('pages.settings.changePassword.oldPassword')}
                  name="oldPassword"
                  type="password"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  validate={required()}
                />
                <TextInput
                  label={translate('pages.settings.changePassword.newPassword')}
                  name="newPassword"
                  type="password"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  validate={[required(), validatePasswordPolicy(translate)]}
                />
                <TextInput
                  label={translate('pages.settings.changePassword.newPasswordConfirm')}
                  name="newPasswordConfirm"
                  type="password"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  validate={[required()]}
                />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={pristine || error || invalid}
                >
                  {translate('pages.settings.changePassword.submitButton')}
                </Button>
              </form>
            )}
          </Form>
        </Paper>

        <Paper className={classes.card}>
          {user ? (
            <TwoFAFlow
              onActivate={handleTwoFacEnable}
              onDeactivate={handleTwoFacDisable}
              user={user}
            />
          ) : <span />}
          <Box width="100%" display="flex" flexDirection="row" justifyContent="end">
            <a href="/third-party-licenses.txt" target="_blank">Third Party Licenses</a>
          </Box>
        </Paper>

      </Box>
    </div>

  );
};

export default SettingsPage;
