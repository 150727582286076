import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import MuiLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as TreeIcon } from '../assets/icons/tree.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '17px 10px',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    width: 'max-content',
    alignItems: 'center',
  },
  pathIcon: {
    marginRight: 28,
  },
}));

const useBreadcrumbStyles = makeStyles({
  root: {
    width: 'max-content',
  },
  li: {
    display: 'flex',
    alignItems: 'center',
  },
  separator: {
    padding: '0 10px',
  },
});

const TitleBreadcrumbs = ({ path, title }) => {
  const classes = useStyles();
  const breadcrumbClasses = useBreadcrumbStyles();

  return (
    <Box className={classes.container} display="flex" flexDirection="row">
      <SvgIcon className={classes.pathIcon}>
        <TreeIcon />
      </SvgIcon>
      <Breadcrumbs classes={breadcrumbClasses} aria-label="breadcrumb">
        {path.map(({ label, to }) => (
          <MuiLink key={to} component={Link} color="inherit" to={to}>
            {label}
          </MuiLink>
        ))}
        <Typography variant="h3">{title}</Typography>
      </Breadcrumbs>
    </Box>
  );
};

TitleBreadcrumbs.propTypes = {
  path: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
  })),
  title: PropTypes.string.isRequired,
};

TitleBreadcrumbs.defaultProps = {
  path: [],
};

export default TitleBreadcrumbs;
