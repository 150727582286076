import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify, useTranslate } from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { parse } from 'query-string';
import { ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { CalendlyEventListener, InlineWidget } from 'react-calendly';
import theme from '../common/theme';
import Icon from '../assets/img/edding-logo.svg';
import httpClient from '../providers/httpClient';
import { baseApi } from '../common/envUtils';

const useStyles = makeStyles(() => ({
  logoImage: {
    objectFit: 'scale-down',
    width: 220,
    height: 64,
    paddingBottom: 38,
  },
  titleContainer: {
    textAlign: 'center',
    paddingBottom: 38,
    borderBottom: (props) => `1px solid ${props.secondaryColor}`,
  },
}));

const ScheduleEventPage = ({ history }) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const [driver, setDriver] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const {
    userId,
  } = parse(history.location.search);

  useEffect(() => {
    dataProvider.getOne('drivers/calendly', { id: userId })
      .then(({ data }) => {
        setDriver(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const handleConfirm = async (msg) => {
    setLoading(true);
    setTimeout(() => {
      httpClient(`${baseApi}/controls/schedule-event`, {
        method: 'POST',
        body: JSON.stringify({
          userId, url: msg.data.payload.event.uri,
        }),
      }).then(() => {
        notify('common.success');
        setLoading(false);
      });
    }, 5000);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container fixed>
        <Box
          height="90vh"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <img className={classes.logoImage} src={Icon} alt="company_logo" />

            <Box
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              {!error
                ? (
                  <>
                    <Box className={classes.titleContainer}>
                      <Typography variant="h3">{translate('pages.scheduleEvent.title')}</Typography>
                    </Box>
                    {loading ? (
                      <LinearProgress />
                    ) : (
                      <CalendlyEventListener
                        onEventScheduled={handleConfirm}
                      >
                        <InlineWidget
                          url="https://calendly.com/easycheck-by-edding/fsk"
                          prefill={{
                            email: driver.email,
                            firstName: driver.firstName,
                            lastName: driver.surName,
                            name: `${driver.firstName} ${driver.surName}`,
                          }}
                        />
                      </CalendlyEventListener>
                    )}

                  </>
                )
                : (
                  <Typography
                    align="center"
                    color="secondary"
                    variant="h3"
                  >
                    {translate('pages.scheduleEvent.invalid')}
                  </Typography>
                )}

            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ScheduleEventPage;
