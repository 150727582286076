import React from 'react';
import {
  Edit, FormWithRedirect, SaveButton, useTranslate,
} from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import UserForm from './UserForm';

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: 'inherit',
  },
}));

const UserEditToolbar = (props) => {
  const classes = useStyles();
  const {
    invalid, pristine, saving, handleSubmitWithRedirect,
  } = props;
  return (
    <div className={classes.toolbar}>
      <SaveButton
        variant="contained"
        saving={saving}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        label="users.actions.save"
        redirect={false}
        submitOnEnter={false}
        disabled={invalid || pristine}
      />
    </div>
  );
};

const UserEdit = (props) => {
  const translate = useTranslate();

  return (
    <div style={{ maxWidth: 800 }}>
      <TitleBreadcrumbs
        title={translate('pages.userEdit.title')}
      />
      <Edit {...props}>
        <FormWithRedirect
          render={(formProps) => (
            <UserForm
              formProps={formProps}
              toolbar={<UserEditToolbar {...formProps} />}
            />
          )}
        />
      </Edit>
    </div>
  );
};

export default UserEdit;
