import React, { useEffect, useState } from 'react';
import {
  Datagrid, List, useDataProvider, useTranslate, TextField, useRefresh, usePermissions,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import MuiTextField from '@material-ui/core/TextField';

import Box from '@material-ui/core/Box';
import DateField from '../components/table/DateField';
import PermissionUtils from '../common/permissionUtils';

const initTableProps = {
  basePath: '/tenants',
  hasCreate: false,
  hasEdit: true,
  hasList: true,
  hasShow: false,
  location: {
    pathname: '/', search: '', hash: '', state: undefined,
  },
  match: {
    path: '/', url: '/', isExact: true, params: {},
  },
  options: {},
  permissions: null,
  resource: 'tenants',
  perPage: 500,
};

const GroupAssignList = ({ superTenantId, groupId }) => {
  const translate = useTranslate();
  const history = useHistory();
  const permissions = new PermissionUtils(usePermissions());
  const dataProvider = useDataProvider();

  const [tenants, setTenants] = useState([]);
  const [selectedTenants, setSelectedTenants] = useState([]);
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);

  const fetchTenants = () => {
    if (groupId) {
      dataProvider.getList('tenants', {
        pagination: { page: 1, perPage: 500 },
        filter: {
          fk_superTenantId: superTenantId,
        },
      }).then((res) => {
        if (res) {
          setTenants(res.data);
        }
      });
    }
  };

  useEffect(() => {
    fetchTenants();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchTenants();
  }, [groupId]);

  const handleCreateClick = () => {
    history.push(`/tenants/create?superTenantId=${superTenantId}&groupId=${groupId}`);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddTenants = () => {
    dataProvider.update('groups', {
      id: groupId,
      data: { tenantIds: selectedTenants.map((t) => t.id) },
    }).then(() => {
      refresh();
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      {permissions.canManage() && (
        <Box display="flex" flexDirection="row">

          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            style={{ width: 200, margin: '20px 0' }}
          >
            {translate('groups.actions.addTenant')}
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={handleCreateClick}
            style={{ width: 200, margin: '20px 10px' }}
          >
            {translate('groups.actions.createTenant')}
          </Button>
        </Box>
      )}

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {translate('groups.actions.addTenant')}
        </DialogTitle>
        <DialogContent>

          <Autocomplete
            id="combo-box-demo"
            options={tenants.filter((t) => t.fk_groupId !== groupId)}
            getOptionLabel={(option) => option.name}
            onChange={(_, values) => setSelectedTenants(values)}
            style={{ width: 300 }}
            multiple
            renderInput={(params) => (
              <MuiTextField
                {...params}
                label={translate('groups.fields.tenant')}
                variant="outlined"
              />
            )}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate('ra.action.cancel')}
          </Button>
          <Button onClick={handleAddTenants} color="primary">
            {translate('ra.action.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <List
        {...initTableProps}
        filterDefaultValues={{ fk_groupId: groupId }}
        pagination={null}
        exporter={false}
        actions={null}
      >
        <Datagrid rowClick="edit">
          <TextField source="name" label="tenants.fields.name" />
          <DateField source="createdAt" label="tenants.fields.createdAt" />
        </Datagrid>
      </List>

    </Box>
  );
};

export default GroupAssignList;
