import React, { useEffect, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import { groupBy, map } from 'lodash';
import { Loading, usePermissions, useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import SvgIcon from '@material-ui/core/SvgIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { stringify } from 'query-string';
import { usePostHog } from 'posthog-js/react';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import { ReactComponent as TotalIcon } from '../assets/icons/dashboard/total.svg';
import { ReactComponent as WarningIcon } from '../assets/icons/dashboard/warning.svg';
import { ReactComponent as LicenseIcon } from '../assets/icons/dashboard/license.svg';
import { ReactComponent as FingerprintIcon } from '../assets/icons/dashboard/fingerprint.svg';
import { ReactComponent as PendingIcon } from '../assets/icons/dashboard/pending.svg';
import { ReactComponent as DoneIcon } from '../assets/icons/dashboard/done.svg';
import { ReactComponent as ControlIcon } from '../assets/icons/dashboard/control.svg';
import { ReactComponent as NeutralIcon } from '../assets/icons/dashboard/neutral.svg';
import DashboardKpi from './DashboardKpi';
import DashboardCard from './DashboardCard';
import { fetchDashboard } from '../redux/statsReducer';
import PermissionUtils from '../common/permissionUtils';
import AutocompleteFilter from '../components/AutocompleteFilter';
import { fetchSettings, updateSettings } from '../redux/settingsReducer';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    width: 450,
  },

  uvvContainer: {
    borderLeft: `1px solid ${theme.palette.primary.light}`,
    padding: '0 46px',
  },
}));

const DashboardPage = ({
  history,
  loading,
  values,
  fetch,
  driverFilter,
  updateFilterSettings,
  fetchUserSettings,
  globalView,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const permissionBase = usePermissions();
  const permissions = useMemo(() => new PermissionUtils(permissionBase), [permissionBase]);
  const posthog = usePostHog();
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/login');
    } else {
      fetchUserSettings();
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetch(globalView ? driverFilter && driverFilter.fk_tenantId : null);
    // eslint-disable-next-line
  }, [driverFilter, globalView]);

  useEffect(() => {
    if (posthog) {
      posthog.identify(permissions.getId());
    }
  }, [posthog, permissions]);

  const handleKpiClick = (customFilter) => (event) => {
    event.stopPropagation();
    history.push({
      pathname: '/drivers',
      search: stringify({ filter: JSON.stringify({ customFilter: customFilter || null }) }, {
        skipEmptyString: true,
        skipNull: true,
      }),
    });
  };

  const handleFilterChange = (tenantFilter) => {
    updateFilterSettings({ ...driverFilter, fk_tenantId: tenantFilter });
  };

  if (loading) {
    return <Loading />;
  }

  const getKpiObjects = () => {
    const getColor = (key) => {
      switch (key) {
        case 'totalDrivers':
          return 'gray';
        case 'newDrivers':
        case 'pendingInit':
        case 'neutralState':
          return 'primary';
        case 'overdueFourWeeks':
        case 'overdueUVVStatus':
        case 'failedControl':
          return 'red';
        case 'pendingControl':
        case 'overdueControl':
        case 'pendingUVVStatus':
          return 'yellow';
        case 'validControl':
        case 'validVisualControl':
        case 'validUVVStatus':
          return 'green';
        default:
          return null;
      }
    };

    const getIcon = (key) => {
      switch (key) {
        case 'totalDrivers':
          return (
            <SvgIcon>
              <TotalIcon />
            </SvgIcon>
          );
        case 'overdueFourWeeks':
        case 'overdueUVVStatus':
          return (
            <SvgIcon>
              <WarningIcon />
            </SvgIcon>
          );
        case 'newDrivers':
        case 'pendingInit':
          return (
            <SvgIcon>
              <LicenseIcon />
            </SvgIcon>
          );
        case 'pendingControl':
          return (
            <SvgIcon>
              <ControlIcon />
            </SvgIcon>
          );
        case 'failedControl':
          return (
            <SvgIcon>
              <FingerprintIcon />
            </SvgIcon>
          );
        case 'overdueControl':
        case 'pendingUVVStatus':
          return (
            <SvgIcon>
              <PendingIcon />
            </SvgIcon>
          );
        case 'validControl':
        case 'validVisualControl':
        case 'validUVVStatus':
          return (
            <SvgIcon>
              <DoneIcon />
            </SvgIcon>
          );
        case 'neutralState':
          return (
            <SvgIcon>
              <NeutralIcon />
            </SvgIcon>
          );
        default:
          return null;
      }
    };

    const displayKeys = [
      'validUVVStatus',
      'pendingUVVStatus',
      'overdueUVVStatus',
      'failedControl',
      'pendingControl',
      'newDrivers',
      'validControl',
      'totalDrivers',
      'neutralState',
    ];

    const noTrendKeys = [
      'validUVVStatus',
      'pendingUVVStatus',
      'overdueUVVStatus',
      'totalDrivers',
    ];

    return map(values, (value, key) => ({
      key,
      color: getColor(key),
      icon: getIcon(key),
      title: translate(`pages.dashboard.values.${key}.title`),
      secLabel: translate(`pages.dashboard.values.${key}.secLabel`),
      count: value.count,
      secondCount: value.secondCount,
      customFilter: value.customFilter,
      secCustomFilter: value.secCustomFilter,
      noTrend: noTrendKeys.includes(key),
    })).filter((value) => value.count !== null)
      .filter(((value) => displayKeys.includes(value.key)));
  };

  const kpiValues = getKpiObjects();
  const uvvValues = kpiValues.filter((kpiValue) => kpiValue.key.includes('UVV'));
  const groupedByColor = groupBy(kpiValues.filter((kpiValue) => !kpiValue.key.includes('UVV')), 'color');

  return (
    <Box width="100%">
      <TitleBreadcrumbs title={translate('pages.dashboard.title')} />

      {((permissions.isTenantAdmin() || permissions.isSuperAdmin()) && globalView) && (
        <Box width="20%" mt={2}>
          <AutocompleteFilter
            disabled={!permissions.isSuperAdmin() && !permissions.isTenantAdmin()}
            resource="tenants"
            label={translate('users.fields.tenant')}
            transferOptions={permissions.isTenantAdmin() ? (o) => ({
              id: o.id,
              name: o.Group ? `${o.name} - ${o.Group.name}` : o.name,
            }) : undefined}
            onChange={handleFilterChange}
            selected={(driverFilter && driverFilter.fk_tenantId) || []}
            width={300}
          />
        </Box>
      )}
      <Box height="100vh" display="flex" flexDirection="row" mt={2}>
        <Box maxWidth={700} flex={2}>
          {map(groupedByColor, (colorValues, color) => (
            <DashboardCard
              key={color}
              color={color}
              classes={{ container: classes.cardContent }}
            >
              {colorValues
                .map(({
                  id, customFilter, secCustomFilter, ...rest
                }) => (
                  <DashboardKpi
                    onClick={handleKpiClick(customFilter)}
                    onSecClick={secCustomFilter ? handleKpiClick(secCustomFilter) : null}
                    key={id}
                    id={id}
                    {...rest}
                  />
                ))}
            </DashboardCard>
          ))}
        </Box>
        {(permissions.isIncludeUvv()) && (
          <Box flex={1} className={classes.uvvContainer}>

            <Box>
              {uvvValues.map(({
                key, customFilter, secCustomFilter, color, ...rest
              }) => (
                <DashboardCard
                  color={color}
                  key={key}
                  classes={{ container: classes.cardContent }}
                >
                  <DashboardKpi
                    color={color}
                    onClick={handleKpiClick(customFilter)}
                    onSecClick={secCustomFilter ? handleKpiClick(secCustomFilter) : null}
                    {...rest}
                  />
                </DashboardCard>
              ))}
            </Box>

          </Box>
        )}

      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  values: state.stats.dashboard,
  loading: state.stats.loading,
  driverFilter: state.settings.driverFilter,
  globalView: state.settings.globalView,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (tenantIds) => {
    dispatch(fetchDashboard(tenantIds));
  },
  updateFilterSettings: (driverFilter) => {
    dispatch(updateSettings({ driverFilter }));
  },
  fetchUserSettings: () => {
    dispatch(fetchSettings());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
