import React from 'react';
import { useTranslate } from 'react-admin';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getLocale } from '../common/dateUtils';

const DateField = ({ value, onChange, ...rest }) => {
  const translate = useTranslate();

  const handleChange = (d) => {
    // check if date is valid
    // eslint-disable-next-line no-restricted-globals
    if ((Object.prototype.toString.call(d) === '[object Date]' && !isNaN(d)) || d === null) {
      onChange(d);
    }
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
      <KeyboardDatePicker
        inputVariant="outlined"
        format="dd.MM.yyyy"
        value={value || null}
        onChange={handleChange}
        clearLabel={translate('ra.action.remove')}
        cancelLabel={translate('ra.action.cancel')}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateField;
