import React, { useEffect, useState } from 'react';
import {
  Button,
  TextInput, useDataProvider, useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { Form } from 'react-final-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { connect } from 'react-redux';
import { stringify } from 'query-string';
import MaterialTable from 'material-table';
import AdminHeader from '../admin/AdminHeader';
import DateInput from '../components/DateField';
import { useOutlinedInputStyles } from '../common/theme';
import { formatDateAsIso } from '../common/dateUtils';
import AutocompleteFilter from '../components/AutocompleteFilter';
import PermissionUtils from '../common/permissionUtils';
import { baseApi } from '../common/envUtils';
import { createDownloadLink } from '../common/utils';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '26px 32px',
    margin: '12px 0',
  },
  picker: {
    width: '48%',
  },
  hint: {
    color: theme.palette.text.secondary,
  },
}));

const DriverReportFilter = ({ globalView, filterValues, setFilters }) => {
  const permissions = new PermissionUtils(usePermissions());
  const inputStyles = useOutlinedInputStyles();
  const translate = useTranslate();
  const classes = useStyles();

  const handleDateChange = (name) => (date) => {
    setFilters({ ...filterValues, [name]: date ? formatDateAsIso(date) : null }, []);
  };

  const handleTenantChange = (selected) => {
    setFilters({ ...filterValues, fk_tenantId: selected }, []);
  };

  const onHandleSubmit = (values) => {
    setFilters(values);
  };

  return (
    <Box>
      <Form onSubmit={onHandleSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              mt={4}
              mb={2}
            >
              <Box width="100%" display="flex">
                <TextInput
                  resettable
                  fullWidth
                  helperText={false}
                  source="q"
                  label=""
                  placeholder={translate('pages.download.search')}
                  variant="outlined"
                  margin="none"
                  onChange={() => handleSubmit()}
                  alwaysOn
                  InputProps={{
                    classes: inputStyles,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {((permissions.isSuperAdmin() || permissions.isTenantAdmin()) && globalView) && (
                  <Box ml={2}>
                    <AutocompleteFilter
                      disabled={!permissions.isSuperAdmin() && !permissions.isTenantAdmin()}
                      resource="tenants"
                      transferOptions={permissions.isTenantAdmin() ? (o) => ({
                        id: o.id,
                        name: o.Group ? `${o.name} - ${o.Group.name}` : o.name,
                      }) : undefined}
                      label={translate('users.fields.tenant')}
                      onChange={handleTenantChange}
                      selected={filterValues.fk_tenantId}
                      width={300}
                    />
                  </Box>
                )}
              </Box>
              <Paper className={classes.card}>
                <Typography variant="subtitle1">
                  {translate('pages.monitoring.range')}
                </Typography>

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  mt={2}
                >
                  <DateInput
                    className={classes.picker}
                    label={translate('common.startDate')}
                    value={filterValues.startDate}
                    maxDate={filterValues.endDate}
                    onChange={handleDateChange('startDate')}
                    disableFuture
                    clearable
                  />
                  <DateInput
                    className={classes.picker}
                    label={translate('common.endDate')}
                    value={filterValues.endDate}
                    minDate={filterValues.startDate}
                    onChange={handleDateChange('endDate')}
                    disableFuture
                    clearable
                  />
                </Box>
              </Paper>
            </Box>
          </form>
        )}
      </Form>
    </Box>
  );
};

const DriverReportList = (props) => {
  const { location, globalView } = props;
  const notify = useNotify();
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const [filterValues, setFilterValues] = useState({});
  const [reportPreview, setReportPreview] = useState([]);

  const fetchReport = async () => {
    const { data } = await dataProvider.getList('drivers/report', {
      filter: filterValues,
    });

    setReportPreview(data);
  };

  useEffect(() => {
    fetchReport();
  }, [filterValues]);

  const fetchReportDownload = async () => {
    let url = `${baseApi}/drivers/report-download`;

    const {
      q, startDate, endDate, ...filter
    } = filterValues;

    const queryString = stringify({
      search: q, filter: JSON.stringify(filter), startDate, endDate,
    }, {
      skipEmptyString: true,
      skipNull: true,
    });

    url = queryString ? `${url}?${queryString}` : url;
    const token = localStorage.getItem('token');
    try {
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status !== 200) {
        notify('pages.download.rangeError');
        return;
      }
      const blob = await res.blob();
      const href = URL.createObjectURL(blob);
      createDownloadLink(href);
    } catch (error) {
      console.error(error); // replace this with your error handling logic
    }
  };

  return (
    <div>
      <AdminHeader active={location.pathname} />
      <Box display="flex" flexDirection="column" alignItems="start">

        <DriverReportFilter
          globalView={globalView}
          filterValues={filterValues}
          setFilters={setFilterValues}
        />

        <Button
          variant="contained"
          label={translate('pages.download.download')}
          onClick={fetchReportDownload}
          style={{ padding: 10 }}
        />

      </Box>

      <Box marginTop={2}>
        <Typography variant="subtitle1">{translate('pages.driverReport.preview')}</Typography>

        <MaterialTable
          title={null}
          style={{
            padding: 0,
            margin: '20px 0',
            width: '100%',
          }}
          columns={[
            { title: translate('drivers.fields.firstName'), field: 'firstName' },
            {
              title: translate('drivers.fields.surName'),
              field: 'surName',
            },
            {
              title: translate('drivers.fields.email'),
              field: 'email',
            },
            {
              title: translate('drivers.fields.statusFS'),
              field: 'status',
            },
            { title: translate('drivers.fields.controlDate'), key: 'controlDate' },
          ]}
          data={reportPreview}
          options={{
            showEmptyDataSourceMessage: false,
            toolbar: false,
            search: false,
            paging: false,
            filtering: false,
            exportButton: false,
          }}
        />
      </Box>

    </div>
  );
};

const mapStateToProps = (state) => ({
  globalView: state.settings.globalView,
});

export default connect(mapStateToProps)(DriverReportList);
