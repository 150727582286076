import React from 'react';
import {
  email,
  FormWithRedirect,
  NumberInput,
  required,
  SaveButton,
  TextInput, useNotify,
  useTranslate,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AdminHeader from './AdminHeader';
import FormSubtitle from '../components/form/FormSubtitle';
import { baseApi } from '../common/envUtils';
import httpClient from '../providers/httpClient';

const useStyles = makeStyles(() => ({
  card: {
    padding: '22px 44px',
    width: 'min-content',
    flexBasis: '48%',
  },
  halfWidth: {
    flexBasis: '48%',
  },
}));

const OrderLabelPage = ({ location }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  const handleSubmit = (data) => {
    const url = `${baseApi}/users/order-label`;
    httpClient(url, { method: 'POST', body: JSON.stringify(data) })
      .then(() => notify('common.success'));
  };
  return (
    <Box width="100%">
      <AdminHeader active={location.pathname} />

      <FormWithRedirect
        render={(formProps) => (
          <form>
            <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
              <Paper className={classes.card}>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  <FormSubtitle>{translate('labels.sections.amount')}</FormSubtitle>
                  <NumberInput
                    margin="none"
                    variant="outlined"
                    source="amount"
                    label="labels.fields.amount"
                    min={0}
                    validate={required()}
                  />

                  <FormSubtitle>{translate('labels.sections.address')}</FormSubtitle>
                  <TextInput
                    className={classes.halfWidth}
                    margin="none"
                    variant="outlined"
                    source="firstName"
                    label="labels.fields.firstName"
                    validate={required()}
                  />
                  <TextInput
                    className={classes.halfWidth}
                    margin="none"
                    variant="outlined"
                    source="surName"
                    label="labels.fields.surName"
                    validate={required()}
                  />

                  <TextInput
                    className={classes.halfWidth}
                    margin="none"
                    variant="outlined"
                    source="email"
                    type="email"
                    validate={[required(), email()]}
                  />

                  <TextInput
                    className={classes.halfWidth}
                    margin="none"
                    variant="outlined"
                    source="companyName"
                    label="labels.fields.companyName"
                    validate={required()}
                  />

                  <TextInput
                    className={classes.halfWidth}
                    margin="none"
                    variant="outlined"
                    source="phone"
                    label="labels.fields.phone"
                    validate={required()}
                  />

                  <TextInput
                    className={classes.halfWidth}
                    margin="none"
                    variant="outlined"
                    source="address"
                    label="labels.fields.street"
                    validate={required()}
                  />

                  <TextInput
                    className={classes.halfWidth}
                    margin="none"
                    variant="outlined"
                    source="zip"
                    label="labels.fields.zip"
                    validate={required()}
                  />
                  <TextInput
                    className={classes.halfWidth}
                    margin="none"
                    variant="outlined"
                    source="city"
                    label="labels.fields.city"
                    validate={required()}
                  />

                  <SaveButton
                    variant="contained"
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    label="labels.actions.send"
                    transform={formProps.transformBeforeSubmit}
                    redirect={false}
                    submitOnEnter={false}
                    onSave={handleSubmit}
                    disabled={formProps.invalid || formProps.pristine}
                  />
                </Box>
              </Paper>
            </Box>
          </form>
        )}
      />
    </Box>
  );
};

export default OrderLabelPage;
