import React from 'react';
import {
  Edit, FormWithRedirect, SaveButton, useNotify, useTranslate,
} from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import DriverForm from './DriverForm';

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: 'inherit',
  },
}));

const transformBeforeSubmit = (data) => ({
  ...data,
  licenseLabelCode: data.licenseLabelCode ? data.licenseLabelCode.trim() : null,
});

const DriverEditToolbar = (props) => {
  const classes = useStyles();
  const {
    invalid, pristine, saving, handleSubmitWithRedirect,
  } = props;
  return (
    <div className={classes.toolbar}>
      <SaveButton
        variant="contained"
        saving={saving}
        transform={transformBeforeSubmit}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        label="drivers.actions.save"
        redirect={false}
        submitOnEnter={false}
        disabled={invalid || pristine}
      />
    </div>
  );
};

const DriverEdit = (props) => {
  const translate = useTranslate();
  const notify = useNotify();

  const onFailure = () => {
    notify('pages.driverCreate.error', 'warning');
  };

  return (
    <div style={{ maxWidth: 800 }}>
      <TitleBreadcrumbs title={translate('pages.driverEdit.title')} />

      <Edit onFailure={onFailure} {...props}>
        <FormWithRedirect
          render={(formProps) => (
            <DriverForm
              formProps={formProps}
              toolbar={<DriverEditToolbar {...formProps} />}
            />
          )}
        />
      </Edit>
    </div>
  );
};

export default DriverEdit;
