import React, { useState } from 'react';
import {
  Confirm,
  ReferenceField,
  TextField,
  useDataProvider,
  useNotify,
  usePermissions,
  useShowController,
  useTranslate,
} from 'react-admin';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SvgIcon from '@material-ui/core/SvgIcon';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PopoverMenu from '../components/PopoverMenu';
import TitleBreadcrumbs from '../layout/TitleBreadcrumbs';
import { ReactComponent as EditIcon } from '../assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from '../assets/icons/trash.svg';
import { ReactComponent as InitIcon } from '../assets/icons/init.svg';
import PermissionUtils from '../common/permissionUtils';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    flexBasis: 380,
    padding: '14px 42px 24px 42px',
  },
  subTitle: {
    margin: '12px 0 6px 0',
  },
  emailText: {
    ...theme.typography.subtitle2,
    fontStyle: 'italic',
  },
  statusCard: {
    width: 160,
    height: 45,
  },
  paddedItem: {
    flex: 1,
    padding: '6px 6px 6px 0',
    minWidth: 150,
  },
  checkbox: {
    width: '100%',
    marginBottom: 10,
  },
  hint: {
    fontSize: 11,
    color: theme.palette.gray.main,
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
}));

const UserShow = (props) => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const permissions = new PermissionUtils(usePermissions());

  const {
    loaded,
    record,
    resource,
    basePath,
  } = useShowController(props);

  const { history } = props;

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  if (!loaded || !record) {
    return <div />;
  }

  const handleDeleteConfirm = async () => {
    try {
      await dataProvider.delete(resource, { id: record.id });
      history.push(`/${resource}`);
      notify('common.success');
    } catch (e) {
      notify('common.fail');
    }
  };

  const handleReset2FA = async () => {
    try {
      await dataProvider.update('/users', {
        id: record.id,
        data: {
          twoFactorEnabled: false,
          twoFactorAuthCode: null,
        },
      });
      notify('common.success');
    } catch (e) {
      notify('common.fail');
    }
  };

  return (
    <div>
      <TitleBreadcrumbs
        path={[{ label: 'Verwaltung', to: `/${resource}` }]}
        title={`${record.firstName} ${record.surName}`}
      />
      <Box display="flex" flexDirection="row" flexWrap="wrap" mt={4}>
        <Paper className={classes.cardContainer}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1" className={classes.subTitle}>
              {translate('users.sections.personalData')}
            </Typography>
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              aria-label="menu"
            >
              <MoreVertIcon />
            </IconButton>
            <Confirm
              isOpen={confirmOpen}
              title="users.actions.delete"
              content="common.confirm"
              onConfirm={handleDeleteConfirm}
              onClose={() => setConfirmOpen(!confirmOpen)}
            />
            <PopoverMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              width={270}
              menuOptions={[
                {
                  hidden: !permissions.checkRole(record.role)
                    || !permissions.canEdit() || permissions.getId() === record.id,
                  icon: (
                    <SvgIcon>
                      <EditIcon />
                    </SvgIcon>),
                  label: translate('users.actions.edit'),
                  onClick: () => {
                    history.push(`/${resource}/${record.id}/edit`);
                  },
                },
                {
                  hidden: !permissions.isSuperAdmin(),
                  disabled: !record.twoFactorEnabled,
                  icon: (
                    <SvgIcon>
                      <InitIcon />
                    </SvgIcon>
                  ),
                  label: translate('users.actions.reset2Fa'),
                  onClick: handleReset2FA,
                },
                {
                  hidden: !permissions.checkRole(record.role) || !permissions.canManage()
                    || permissions.getId() === record.id,
                  icon: (
                    <SvgIcon>
                      <TrashIcon />
                    </SvgIcon>),
                  label: translate('users.actions.delete'),
                  onClick: () => {
                    setConfirmOpen(true);
                  },
                },
              ]}
            />

          </Box>
          <Typography variant="h6">{`${record.firstName} ${record.surName}`}</Typography>
          <Typography className={classes.emailText}>{record.email}</Typography>

          <Box>

            {permissions.isGroupAdmin() && (
              <>
                <Typography
                  variant="subtitle1"
                  className={classes.subTitle}
                >
                  {translate('users.sections.companyDetails')}
                </Typography>
                <ReferenceField
                  basePath={basePath}
                  record={record}
                  resource={resource}
                  source="fk_tenantId"
                  reference="tenants"
                >
                  <TextField source="name" />
                </ReferenceField>
              </>
            )}

            <Box my={1}>
              <Typography variant="subtitle2">{translate('users.fields.role')}</Typography>
              <Typography variant="body1">{translate(`users.roles.${record.role}`)}</Typography>
            </Box>

          </Box>

          <Box>
            <Typography
              variant="subtitle1"
              className={classes.subTitle}
            >
              {translate('users.sections.rights')}
            </Typography>

            <FormControlLabel
              className={classes.checkbox}
              control={(
                <Checkbox
                  checked={record.canRead}
                  color="primary"
                  disabled
                />
              )}
              label={
                (
                  <div className={classes.labelContainer}>
                    <Typography variant="body1">{translate('users.fields.canRead')}</Typography>
                    <span className={classes.hint}>
                      {translate('users.hints.canRead')}
                    </span>
                  </div>
                )
              }
            />

            <FormControlLabel
              className={classes.checkbox}
              control={(
                <Checkbox
                  checked={record.canEdit}
                  color="primary"
                  disabled
                />
              )}
              label={
                (
                  <div className={classes.labelContainer}>
                    <Typography variant="body1">{translate('users.fields.canEdit')}</Typography>
                    <span className={classes.hint}>
                      {translate('users.hints.canEdit')}
                    </span>
                  </div>
                )
              }
            />

            <FormControlLabel
              className={classes.checkbox}
              control={(
                <Checkbox
                  checked={record.canManage}
                  color="primary"
                  disabled
                />
              )}
              label={
                (
                  <div className={classes.labelContainer}>
                    <Typography variant="body1">{translate('users.fields.canManage')}</Typography>
                    <span className={classes.hint}>
                      {translate('users.hints.canManage')}
                    </span>
                  </div>
                )
              }
            />

            <FormControlLabel
              className={classes.checkbox}
              control={(
                <Checkbox
                  checked={record.isNotified}
                  color="primary"
                  disabled
                />
              )}
              label={
                (
                  <div className={classes.labelContainer}>
                    <Typography variant="body1">{translate('users.fields.isNotified')}</Typography>
                    <span className={classes.hint}>
                      {translate('users.hints.isNotified')}
                    </span>
                  </div>
                )
              }
            />

          </Box>

        </Paper>
      </Box>
    </div>
  );
};

export default UserShow;
