import React from 'react';
import { Layout } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import MyMenu from './Menu';
import MyAppBar from './AppBar';
import MySidebar from './Sidebar';

const useLayoutStyles = makeStyles({
  appFrame: {
    marginTop: 0,
  },
  content: {
    marginTop: 77,
    margin: '77px 36px 0 36px',
  },
});

const MyLayout = (props) => {
  const classes = useLayoutStyles();
  return (
    <Layout
      {...props}
      classes={classes}
      appBar={MyAppBar}
      menu={MyMenu}
      sidebar={MySidebar}
    />
  );
};

export default MyLayout;
