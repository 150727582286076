/* eslint-disable camelcase */
import React from 'react';
import {
  Datagrid, downloadCSV, List, TextField, useTranslate,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import AdminHeader from './AdminHeader';
import MyPagination from '../components/table/Pagination';
import DateTimeField from '../components/table/DateTimeField';
import SearchFilter from '../components/table/SearchFilter';

const LevelField = ({ record, source }) => {
  const translate = useTranslate();

  if (record && record[source] != null) {
    return <span>{translate(`logs.levels.${record[source]}`)}</span>;
  }
  return <span />;
};

const LogsList = (props) => {
  const { basePath } = props;
  const translate = useTranslate();

  const exporter = (logs) => {
    const postsForExport = logs.map((log) => {
      const {
        updatedAt, id, fk_tenantId, level, ...logForExport
      } = log;

      logForExport.tenantId = fk_tenantId;
      logForExport.level = level ? translate(`logs.levels.${level}`) : null;
      return logForExport;
    });
    jsonExport(postsForExport, {
      headers: ['text', 'level', 'createdAt', 'tenantId'], // order fields in the export
    }, (err, csv) => {
      downloadCSV(csv, `dlcr_logs_${new Date().toISOString()}`); // download as 'posts.csv` file
    });
  };

  return (
    <div>
      <AdminHeader active={basePath} />
      <List
        pagination={<MyPagination />}
        exporter={exporter}
        filters={<SearchFilter />}
        bulkActionButtons={false}
        {...props}
      >
        <Datagrid>
          <TextField source="text" label="logs.fields.text" />
          <LevelField source="level" label="logs.fields.level" />
          <DateTimeField source="createdAt" label="logs.fields.createdAt" />
        </Datagrid>
      </List>
    </div>
  );
};

export default LogsList;
