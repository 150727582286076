import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import {
  AutocompleteInput,
  choices,
  email,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceInput,
  regex,
  required,
  SelectInput,
  TextInput,
  usePermissions,
  useTranslate,
} from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import { usePostHog } from 'posthog-js/react';
import FormDateField from '../components/form/FormDateField';
import FormSubtitle from '../components/form/FormSubtitle';
import PermissionUtils from '../common/permissionUtils';
import httpClient from '../providers/httpClient';
import { baseApi } from '../common/envUtils';
import FormCheckbox from '../components/form/FormCheckbox';
import { featureFlags } from '../posthog/featureFlags';

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 40px 30px 40px',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  halfWidth: {
    flexBasis: '48%',
  },
  toolbar: {
    backgroundColor: 'inherit',
  },
  warning: {
    '& input:valid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
}));

const DriverForm = ({ toolbar }) => {
  const classes = useStyles();
  const posthog = usePostHog();
  const translate = useTranslate();
  const [codes, setCodes] = useState([]);
  const permissions = new PermissionUtils(usePermissions());

  useEffect(() => {
    httpClient(`${baseApi}/label-codes`).then(({ json }) => {
      setCodes(json);
    });
  }, []);

  const validateLabelCode = choices(codes, translate('pages.driverEdit.codeHint'));

  const validateLabelCodeWithNeutralState = (value) => {
    if (posthog.isFeatureEnabled(featureFlags.neutralState) && !value) {
      return undefined;
    }
    return choices(codes, translate('pages.driverEdit.codeHint'))(value);
  };

  // return true if label code is 00000 to show warning message to the user
  const checkLabelCodeWarning = (labelCode) => labelCode === '00000';

  const getTenantFilter = () => {
    if (permissions.isTenantAdmin()) {
      return {
        fk_superTenantId: permissions.getSuperTenantId(),
      };
    }
    if (permissions.isGroupAdmin()) {
      return { fk_groupId: permissions.getGroupId() };
    }
    return null;
  };

  return (
    <form className={classes.container}>
      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
        <FormSubtitle>Allgemeine Kontaktdaten</FormSubtitle>
        <TextInput
          className={classes.halfWidth}
          margin="none"
          variant="outlined"
          source="firstName"
          label="drivers.fields.firstName"
          validate={required()}

        />
        <TextInput
          className={classes.halfWidth}
          margin="none"
          variant="outlined"
          source="surName"
          label="drivers.fields.surName"
          validate={required()}
        />
        {permissions.isAllowSms() ? (
          <>
            <RadioButtonGroupInput
              source="contactType"
              fullWidth
              label="drivers.contactType.label"
              validate={required()}
              style={{ marginLeft: 10, marginTop: 0 }}
              row
              choices={[
                { id: 0, name: translate('drivers.contactType.0') },
                { id: 1, name: translate('drivers.contactType.1') },
              ]}
            />
            <TextInput
              className={classes.halfWidth}
              margin="none"
              variant="outlined"
              source="email"
              label="drivers.fields.email"
              validate={[email(), (value, allValues) => !value && allValues.contactType === 0]}
            />
            <TextInput
              className={classes.halfWidth}
              margin="none"
              variant="outlined"
              source="phoneNumber"
              label="drivers.fields.phoneNumber"
              validate={[regex(/(\(?([\d \-)–+/(]+){6,}\)?([ .\-–/]?)([\d]+))/, 'drivers.fields.phoneNumberError'), (value, allValues) => !value && allValues.contactType === 1]}
            />
          </>
        ) : (
          <TextInput
            fullWidth
            margin="none"
            variant="outlined"
            source="email"
            label="drivers.fields.email"
            validate={[required(), email()]}
          />
        )}

        {(permissions.isSuperAdmin()
          || (permissions.getSuperTenantId() && permissions.isTenantAdmin())
          || (permissions.getGroupId() && permissions.isGroupAdmin())
        ) && (
          <ReferenceInput
            key="tenant"
            label="drivers.fields.tenant"
            source="fk_tenantId"
            reference="tenants"
            filter={getTenantFilter()}
          >
            <AutocompleteInput
              fullWidth
              margin="none"
              variant="outlined"
              optionText="name"
              validate={required()}
              allowEmpty
            />
          </ReferenceInput>
        )}

        <RadioButtonGroupInput
          source="driverTypeEnum"
          label=""
          validate={required()}
          style={{ marginLeft: 10, marginTop: 0 }}
          row
          choices={[
            { id: 0, name: translate('drivers.driverTypes.0') },
            { id: 1, name: translate('drivers.driverTypes.1') },
          ]}
        />
        {(permissions.isLkw() && permissions.isPkw()) && (
          <Box>
            <FormSubtitle>{translate('drivers.sections.vehicleTypes')}</FormSubtitle>
            <FormCheckbox
              source="isPkw"
              label="drivers.fields.isPkw"
              className={classes.halfWidth}
            />
            <FormCheckbox
              source="isLkw"
              label="drivers.fields.isLkw"
              className={classes.halfWidth}
            />
          </Box>
        )}
      </Box>

      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
        <FormSubtitle>{translate('drivers.sections.licenseInfo')}</FormSubtitle>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.driverTypeEnum === 0
            && (
              <>
                <SelectInput
                  className={classes.halfWidth}
                  margin="none"
                  variant="outlined"
                  source="licenseIntervalDays"
                  label="drivers.fields.licenseInterval"
                  choices={[
                    {
                      id: 3,
                      name: `3 ${translate('drivers.fields.licenseIntervalPostfixDays')}`,
                    },
                    ...[1, 2, 3, 4, 5, 6].map((val) => ({
                      id: val * 30,
                      name: `${val} ${translate('drivers.fields.licenseIntervalPostfix')}`,
                    })),
                  ]}
                  {...rest}
                />

                <TextInput
                  className={classNames(
                    classes.halfWidth,
                    {
                      [classes.warning]: checkLabelCodeWarning(formData.licenseLabelCode),
                    },
                  )}
                  margin="none"
                  variant="outlined"
                  source="licenseLabelCode"
                  label="drivers.fields.licenseLabelCode"
                  helperText={checkLabelCodeWarning(formData.licenseLabelCode) ? translate('drivers.fields.invalidLabelCode') : undefined}
                  validate={
                    posthog.isFeatureEnabled(featureFlags.neutralState)
                      ? validateLabelCodeWithNeutralState
                      : [required(), validateLabelCode]
                  }
                />
                <SelectInput
                  className={classes.halfWidth}
                  margin="none"
                  variant="outlined"
                  source="licenseType"
                  label="drivers.fields.licenseType"
                  choices={[
                    { id: 0, name: translate(`drivers.licenseType.${0}`) },
                    { id: 1, name: translate(`drivers.licenseType.${1}`) },
                    { id: 2, name: translate(`drivers.licenseType.${2}`) },
                  ]}
                />
              </>
            )}
        </FormDataConsumer>

        <FormDateField
          style={{ marginBottom: 19 }}
          classes={{ root: classes.halfWidth }}
          source="licenseExpiryDate"
          label="drivers.fields.licenseExpiryDate"
        />

        <TextInput
          classes={{ root: classes.halfWidth }}
          margin="none"
          variant="outlined"
          source="licenseComment1"
          label={permissions.getCommentField1Name()}
        />
        {permissions.getCommentField2Name() && (
          <TextInput
            classes={{ root: classes.halfWidth }}
            margin="none"
            variant="outlined"
            source="licenseComment2"
            label={permissions.getCommentField2Name()}
          />
        )}

      </Box>
      {toolbar}
    </form>

  );
};

export default DriverForm;
